import React from "react";

function SketchesComponent() {
  return (
    <div className="sketches text-center py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-4 d-flex">
            <div className="row">
              <div className="col-md-12 sketch">
                <img
                  src="img/comics/sketch1.png"
                  alt="Sketch"
                  className="img-fluid card-item"
                />
              </div>
              <div className="col-md-12 sketch">
                <img
                  src="img/comics/sketch3.png"
                  alt="Sketch"
                  className="img-fluid card-item"
                />
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex">
            <div className="row">
              <div className="col-md-12 sketch">
                <img
                  src="img/comics/sketch6.png"
                  alt="Sketch"
                  className="img-fluid card-item"
                />
              </div>
              <div className="col-md-12 sketch">
                <img
                  src="img/comics/sketch2.png"
                  alt="Sketch"
                  className="img-fluid card-item"
                />
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex">
            <div className="row">
              <div className="col-md-12 sketch">
                <img
                  src="img/comics/sketch7.png"
                  alt="Sketch"
                  className="img-fluid card-item"
                />
              </div>
              <div className="col-md-6 sketch">
                <img
                  src="img/comics/sketch5.png"
                  alt="Sketch"
                  className="img-fluid card-item"
                />
              </div>
              <div className="col-md-6 sketch">
                <img
                  src="img/comics/sketch4.png"
                  alt="Sketch"
                  className="img-fluid card-item"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SketchesComponent;
