import React from "react";
import FAQComponent from "./FAQComponent";
import DevTeam from "./DevTeam";
import WizComics from "./WizComics";
import LegionCards from "./LegionCards";
import Capsule from "./Capsule";
import Banner from "./Banner";

function Home() {
  return (
    <>
      <Banner />
      <Capsule />
      <LegionCards />
      <WizComics />
      <DevTeam />
      <FAQComponent />
    </>
  );
}

export default Home;
