import React from "react";
import { Link } from "react-router-dom";

function WizComicsComponent() {
  return (
    <div>
      <div className="text-center py-5">
        <div className="container">
        <br />
          <div className="row section">
            <div className="col-lg-6 bg-text">
              <h2 className="dystopunks">WIZ COMICS UNIVERSE</h2>
              <br />
              <p className="lead">
                The series WIZ represents a special sub-project of{" "}
                <a
                  href="https://encode.graphics/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ENCODE Graphics
                </a>{" "}
                and was created together with the{" "}
                <a
                  href="https://dystopunks.net/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  DystoPunks
                </a>{" "}
                NFT community. The special thing about WIZ is that this
                high-quality comic is basically exclusively available digitally
                and is free to read for everyone. Holders of the{" "}
                <a
                  href="https://opensea.io/collection/encode-key"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ENCODE Keys
                </a>{" "}
                can claim the comics in different variants as NFTs in the NFT
                Store of the{" "}
                <a
                  href="https://encode.network/store"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ENCODE Network
                </a>
                , where, in addition, a public NFT edition is also available for
                purchase by non-holders. More strictly limited physical issues
                with NFTs will also be available there in the future.
              </p>
            </div>
            <div className="col-lg-6 bg-text">
              <h2 className="dystopunks">WHAT IS WIZ ABOUT?</h2>
              <br />
              <p className="lead">
                In a dystopian future, which has been shaken by a catastrophe of
                global proportions, we meet the characters Wiz, Raiden and
                Nathaniel, who seek to prevent the reawakening of an artificial
                intelligence called Datamaster. In doing so, they hope to get
                help from Arian, the leader of the Ariasi. Hunted by Dante and
                his henchman Commander G, they must make their way through the
                ruins of Greystone City to avert the danger.
              </p>
            </div>
          </div>
          </div>

          <div className="keys text-center py-3">
            <div className="container">
              <div className="row section">
                <h5>
                  You can get an ENCODE Key{" "}
                  <a
                    href="https://opensea.io/collection/encode-key"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    HERE
                  </a>{" "}
                  on OpenSea and you can claim the comics as NFTs{" "}
                  <a
                    href="https://encode.network/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    HERE
                  </a>{" "}
                  on ENCODE Network.
                </h5>
              </div>
            </div>
          </div>
      </div>

      <div className="text-center py-5">
        <div className="container">
          <div className="row section">
            <div className="col-md-4">
              <a
                href="https://www.encode.graphics/_files/ugd/b97f9a_32022ca5acee460b983d9b386c49bfef.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="img/comics/wiz1.jpg"
                  alt="Wiz"
                  className="img-fluid card-item"
                />
              </a>
              <br /><br />
              <a
                href="https://www.encode.graphics/_files/ugd/b97f9a_32022ca5acee460b983d9b386c49bfef.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-lg btn-secondary"
              >
                READ WIZ #1
              </a>
              <br /><br />
            </div>
            <div className="col-md-4">
              <a
                href="https://www.encode.graphics/_files/ugd/b97f9a_dd6f4e74cdf4487f8a46153f54a47d73.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="img/comics/wiz2.jpg"
                  alt="Wiz"
                  className="img-fluid card-item"
                />
              </a>
              <br /><br />
              <a
                href="https://www.encode.graphics/_files/ugd/b97f9a_dd6f4e74cdf4487f8a46153f54a47d73.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-lg btn-secondary"
              >
                READ WIZ #2
              </a>
              <br /><br />
            </div>
            <div className="col-md-4">
              <a
                href="https://www.encode.graphics/_files/ugd/b97f9a_2305d9df1bfe4133802b729bfe361032.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="img/comics/wiz3.jpg"
                  alt="Wiz"
                  className="img-fluid card-item"
                />
              </a>
              <br /><br />
              <a
                href="https://www.encode.graphics/_files/ugd/b97f9a_2305d9df1bfe4133802b729bfe361032.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-lg btn-secondary"
              >
                READ WIZ #3
              </a>
            </div>
          </div>
        </div>
        <br />
      </div>

      <div className="trylogi text-center py-3">
      <br />
        <div className="container">
          <h2 className="dystopunks">NEXT WIZ TRILOGY</h2>
          <br />
          <p className="lead">
            We are currently working on the next WIZ trilogy. We have written
            all three scripts for the second arc and created a nice bridge for
            the upcoming ENCODE x DystoPunks project{" "}
            <Link to="/" target="_blank" rel="noopener noreferrer">
              LEG1ON
            </Link>
            .
          </p>
          <br />
        </div>
      </div>
    </div>
  );
}

export default WizComicsComponent;
