import React, { useEffect, useState } from 'react'
import DystoFreeClaim from './mint-cards/DystoFreeClaim'
import EncodeKeyFreeClaim from './mint-cards/EncodeKeyFreeClaim'
import FreeMint from './mint-cards/FreeMint'
import VxAllowlistMint from './mint-cards/VxAllowlistMint'
import PublicMint from './mint-cards/PublicMint'
import AllowlistMint from './mint-cards/AllowlistMint'
import CheckClaimEligibility  from './checker/CheckClaimEligibility'
import { useAccount, useConnect, useEnsName, useDisconnect } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { useLeg1onTotalSupply } from '../generated.ts'
import Countdown from "react-countdown";
import SoldOut from './mint-cards/SoldOut.js'

function MintComp() {
    const { address, isConnected } = useAccount()
    const { data: ensName } = useEnsName({ address })
    const { connect } = useConnect({
        connector: new InjectedConnector(),
    })
    const { disconnect } = useDisconnect()
    const [soldOut, setSoldOut] = useState(false)
    const maxSupply = 7777
    const startTimeInMs = 1703707200 * 1000
    const started = Date.now() >= startTimeInMs
    const {data: numberOfMints} = useLeg1onTotalSupply({watch: isConnected})

    useEffect(() => {
      if (parseInt(numberOfMints) === maxSupply){
        setSoldOut(true)
      }
    }, [numberOfMints])
    
    console.log(numberOfMints)
  return (
    <>
    {!started && <p className='text-white'><Countdown date={startTimeInMs}/></p>}
        {!isConnected ?
         <a href="https://blur.io/collection/leg1on" target="_blank" rel="noopener noreferrer">
        <button disabled={!started}  className="btn btn-lg btn-secondary">Trade on Blur</button>
        </a>
        :
        <>
        {soldOut ? 
            <SoldOut />
        :
        <>
            <button onClick={() => disconnect()} className="btn btn-lg btn-secondary">DISCONNECT</button>
            <p>Connected to {ensName ?? address}</p>
            {numberOfMints && <p className='text-white'>NFTs Minted: {numberOfMints.toString()}</p>}
            <DystoFreeClaim address={address} />
            <EncodeKeyFreeClaim address={address} />
            <FreeMint address={address} />
            <VxAllowlistMint address={address} />
            <AllowlistMint address={address}  />
            <PublicMint />
            <CheckClaimEligibility  /> 
        </>
        }
        </>
        }  
    </>
  )
}

export default MintComp
