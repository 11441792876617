import React from "react";

const Footer = () => {
  return (
    <footer className="container footer py-5">
      <div className="row">
        <div className="col-12 col-md d-none d-md-block">
          <a
            href="https://encode.graphics/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="img/logos/encode.png"
              alt="ENCODE Graphics"
              className="navlogo mb-2 img-fluid"
            />
          </a>
          <br />
          <a
            href="https://dystopunks.net/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="img/logos/dystopunks.png"
              alt="DystoPunks"
              className="navlogo mb-2 img-fluid"
            />
          </a>
          <br />
          <a
            href="https://app.uniswap.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="img/logos/creds.png"
              alt="$CREDS"
              className="navlogo mb-2 img-fluid"
            />
          </a>
          <br />
        </div>

        <div className="col-6 col-md d-md-none">
          <a
            href="https://encode.graphics/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="img/logos/encode.png"
              alt="ENCODE Graphics"
              className="navlogo mb-2 img-fluid"
            />
          </a>
          <br />
          <a
            href="https://dystopunks.net/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="img/logos/dystopunks.png"
              alt="DystoPunks"
              className="navlogo mb-2 img-fluid"
            />
          </a>
          <br />
          <a
            href="https://coingecko.com/en/coins/creds"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="img/logos/creds.png"
              alt="$CREDS"
              className="navlogo mb-2 img-fluid"
            />
          </a>
          <br />
        </div>

        <div className="col-6 col-md d-none d-md-block">
          <h5>ENCODE Graphics</h5>
          <ul className="list-unstyled text-small">
            <li>
              <a
                className="link-secondary"
                href="https://encode.graphics/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Website
              </a>
            </li>
            <li>
              <a
                className="link-secondary"
                href="https://encode.network/"
                target="_blank"
                rel="noopener noreferrer"
              >
                ENCODE Network
              </a>
            </li>
            <li>
              <a
                className="link-secondary"
                href="https://opensea.io/collection/encode-key"
                target="_blank"
                rel="noopener noreferrer"
              >
                ENCODE Keys
              </a>
            </li>
            <li>
              <a
                className="link-secondary"
                href="https://twitter.com/ENCODE_graphics"
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </a>
            </li>
            <li>
              <a
                className="link-secondary"
                href="https://discord.gg/HkAkaJbD2K"
                target="_blank"
                rel="noopener noreferrer"
              >
                Discord
              </a>
            </li>
          </ul>
        </div>

        <div className="col-6 col-md d-none d-md-block">
          <h5>DystoPunks</h5>
          <ul className="list-unstyled text-small">
            <li>
              <a
                className="link-secondary"
                href="https://dystopunks.net/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Website
              </a>
            </li>
            <li>
              <a
                className="link-secondary"
                href="https://litepaper.dystopunks.net/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Litepaper
              </a>
            </li>
            <li>
              <a
                className="link-secondary"
                href="https://opensea.io/collection/dystopunks"
                target="_blank"
                rel="noopener noreferrer"
              >
                DystoPunks NFTs
              </a>
            </li>
            <li>
              <a
                className="link-secondary"
                href="https://twitter.com/DystoPunks"
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </a>
            </li>
            <li>
              <a
                className="link-secondary"
                href="https://discord.gg/Dystolabs"
                target="_blank"
                rel="noopener noreferrer"
              >
                Discord
              </a>
            </li>
          </ul>
        </div>

        <div className="col-6 col-md d-md-none">
          <h5>ENCODE x DystoPunks</h5>
          <ul className="list-unstyled text-small">
            <li>
              <a
                className="link-secondary"
                href="https://litepaper.dystopunks.net/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Litepaper
              </a>
            </li>
            <li>
              <a
                className="link-secondary"
                href="https://opensea.io/collection/dystopunks"
                target="_blank"
                rel="noopener noreferrer"
              >
                DystoPunks NFTs
              </a>
            </li>
            <li>
              <a
                className="link-secondary"
                href="https://opensea.io/collection/encode-key"
                target="_blank"
                rel="noopener noreferrer"
              >
                ENCODE Keys
              </a>
            </li>
            <li>
              <a
                className="link-secondary"
                href="https://twitter.com/DystoPunks"
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </a>
            </li>
            <li>
              <a
                className="link-secondary"
                href="https://discord.gg/Dystolabs"
                target="_blank"
                rel="noopener noreferrer"
              >
                Discord
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
