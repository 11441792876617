import React, { useEffect, useState } from 'react'
import './styles.css';
import { useLeg1onAvailableKeyClaim, useLeg1onKeyClaim, usePrepareLeg1onKeyClaim } from '../../generated.ts';
import { useWaitForTransaction } from 'wagmi';

function EncodeKeyFreeClaim({ address }) {
  const [ hasEncodeKey, setHasEncodeKey ] = useState(false)
  const [keys, setKeys] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([]);

  const handleCheckboxChange = (key, isChecked) => {
    if (isChecked) {
      setSelectedKeys(prevSelected => [...prevSelected, key]);
    } else {
      setSelectedKeys(prevSelected => prevSelected.filter(k => k !== key));
    }
  };

  const { data: keysOfUser } = useLeg1onAvailableKeyClaim({
    args: [address],
  })

  useEffect(() => {
    setHasEncodeKey(false)
  }, [address])

  useEffect(() => {
    if (keysOfUser && Object.keys(keysOfUser).length === 0 ) {
      
      setHasEncodeKey(false)
      setSelectedKeys([])
      setKeys([])
    } else {
      setKeys(keysOfUser)
      setHasEncodeKey(true)
      setSelectedKeys(keysOfUser)
    }
  }, [keysOfUser])

  
  
  

  const {  config,
    error: prepareError,
    isError: isPrepareError,} = usePrepareLeg1onKeyClaim({
      enabled: hasEncodeKey,
      args: [selectedKeys],
  })

  const { data: mintData, error, isError, isLoading: isMintLoading, write } = useLeg1onKeyClaim(config)

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: mintData?.hash,
  })

  // console.log(keys)

  const keyTypes = ['bronze', 'silver', 'gold'];
  // console.log("Selected Encode Keys: ", selectedKeys)

  if (prepareError || isPrepareError){
    console.log(prepareError || isPrepareError)
  }
  
  if (error) {
    console.log(error)
  }

  if (hasEncodeKey)
  return (<>
  <br />
    <div className="free-mint py-4 px-4 mint-item">
      <div className="row">
      <div className="col-md-6">
          <h4 className="strong">KEY FREE CLAIM</h4>
        </div>
        <div className="col-md-3">
          <h4 className="free-mint-tag">27 DEC</h4>
        </div>
        <div className="col-md-3">
          <h4 className="free-mint-tag">0.00 ETH</h4>
        </div>
      </div>
      <p className="mint-text">
        Encode Keys Free Claims:
      </p>
      <div className="row">
        {keys?.map((key, index) => (
          <div className="col-md-2" key={key}>
            <img src={`img/encodekeys/${key <= 50 ? keyTypes[2]:key <= 450 ? keyTypes[1]:keyTypes[0]}.png`} alt="ENCODE Key" className="img-fluid collection" />
            <div className="form-check">
              <input 
                className="form-check-input" 
                type="checkbox" 
                id={`encode-${key}`} 
                onChange={(e) => handleCheckboxChange(key, e.target.checked)}
                checked={selectedKeys.includes(key)}
              />
              <label className="form-check-label" htmlFor={`encode-${key}`}>Claim</label>
            </div>
          </div>
        ))}
      </div>

      <div className="col-md-12">
        <button className="btn btn-secondary"onClick={() => write?.()}
            disabled={isMintLoading || isLoading || isPrepareError || selectedKeys < 1}
          >
            {isMintLoading && "AWAITING..."}
            {!isMintLoading && !isLoading && !isSuccess && "CLAIM SELECTED"}
            {!isMintLoading && !isLoading && isSuccess && "MINTED!"}
            {isLoading && "CLAIMING..."}</button>
        </div>
        {isError && <h6>There was an error.</h6>}
    </div>
    </>
  );
}

export default EncodeKeyFreeClaim