import { MerkleTree } from 'merkletreejs'

import { Buffer } from "buffer";
import {whitelist} from '../merkle/merklelist'
import { getAddress, keccak256 } from 'viem';

const padBuffer = (addr) => {
    return Buffer.from(addr.substr(2).padStart(32 * 2, "0"), 'hex');
};
  
const checksum = (addr) => {
    return getAddress(addr)
}
  
let addresses = whitelist;
let checksummed = addresses.map(addr => checksum(addr))

let leaves = checksummed.map(addr => padBuffer(addr));
let merkleTree = new MerkleTree(leaves, keccak256, { sortPairs: true });
let rootHash = merkleTree.getHexRoot();

function whitelistVerifier(address) {
    let proof = merkleTree.getHexProof(padBuffer(address));
    const user = checksummed.find(claim => {
        return claim === address;
    });

    if(typeof user !== 'undefined') {
        console.log("Address: ", address, "\nMerkle Proof Allowlist: ", proof, "\nRoot: ", rootHash)
        return proof;
    } else {
        console.log("ALLOW LIST ROOT: ", rootHash)
        return "No whitelist found."
    }
}

export default whitelistVerifier;