import React, { useEffect, useState } from 'react'
import './styles.css';
import { useWaitForTransaction } from 'wagmi'

import { useLeg1onPublicMint, usePrepareLeg1onPublicMint } from '../../generated.ts'
import { parseEther } from 'viem';

function PublicMint() {
    const pricePerMint = '0.07';
    const [ mintAmount, setMintAmount ] = useState('')
    const [ price, setPrice ] = useState('')
    const [mintEnabled, setMintEnabled] = useState(false)

    // for testing
    const isMintDisabled = false;

    const {
      config,
      error: prepareError,
      isError: isPrepareError,
      } = usePrepareLeg1onPublicMint({
          enabled: mintEnabled,
          args: [mintAmount],
          value: parseEther(price),
      })

    const { data, error, isError, isLoading: isMintLoading, write } = useLeg1onPublicMint(config)
    
    const { isLoading, isSuccess } = useWaitForTransaction({
        hash: data?.hash,
    })

  const handleMintAmountChange = (e) => {
    if (e.target.value >= 1 && e.target.value <= 7) {
      setMintAmount(e.target.value);
      setPrice(String(parseFloat(pricePerMint) * parseInt(e.target.value)))
    } else {
      setMintAmount(1);
      setPrice(String(parseFloat(pricePerMint) * parseInt(1)))
    }
  };

  if (prepareError || isPrepareError){
    console.log(prepareError)
  }
  
  if (error) {
    console.log(error)
  }

  useEffect(() => {
    if(parseInt(mintAmount) > 0) {
      setMintEnabled(true)
    } else {
      setMintEnabled(false)
    }
  }, [mintAmount])
  

  return (<>
  <br />
    <div className="mint-disabled py-4 px-4 mint-item">
      <div className="row">
        <div className="col-md-6">
          <h4 className="strong">PUBLIC MINT</h4>
        </div>
        <div className="col-md-3">
          <h4 className="mint-tag-disabled">28 DEC</h4>
        </div>
        <div className="col-md-3">
          <h4 className="mint-tag-disabled">0.07 ETH</h4>
        </div>
      </div>
      <p className="mint-text">
        You can mint up to 7 NFTs per trx:
      </p>
      <div className="row">
        <div className="col-md-4 py-1 px-1">
          <input 
            type="number" 
            className="form-control" 
            placeholder="Max. 7" 
            min="1" 
            max="7" 
            value={mintAmount}
            onChange={handleMintAmountChange}
            aria-label="Dysto Claim" 
            aria-describedby="mint-button"
          />
        </div> 
        <div className="col-md-6">
          <button 
            className={`btn ${isMintDisabled ? 'disabled' : ''} btn-secondary`}
            onClick={() => write?.()}
            disabled={isMintLoading || isLoading || isPrepareError}
          >
            {isMintLoading && "AWAITING..."}
            {!isMintLoading && !isLoading && !isSuccess && "MINT"}
            {!isMintLoading && !isLoading && isSuccess && "MINTED!"}
            {isLoading && "MINTING..."}
          </button>
        </div>
        {isError && <h6>There was an error.</h6>}
      </div>
    </div></>
  );
}

export default PublicMint