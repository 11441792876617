import React from "react";
import { NavLink } from "react-router-dom";
import opensea from '../opensea.svg'

const Header = () => {
  return (
    <header className="site-header sticky-top">
      <nav className="navbar navbar-expand-lg navbar-dark" aria-label="Navbar">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img
              src="img/logos/leg1on.png"
              alt="LEG1ON"
              className="navlogo img-fluid"
            />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#LEG1ON_Navbar"
            aria-controls="navbarsExample07"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="LEG1ON_Navbar">
            <ul className="nav nav-pills mt-2 ms-auto">
              <li className="nav-item">
                <NavLink to="/" className={({ isActive }) => (isActive ? 'active nav-link' : 'inactive nav-link')}>
                  HOME
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="mint" className={({ isActive }) => (isActive ? 'active nav-link' : 'inactive nav-link')}>
                  MINT
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="wiz" className={({ isActive }) => (isActive ? 'active nav-link' : 'inactive nav-link')}>
                  WIZ COMICS
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="https://litepaper.dystopunks.net/encode-network/leg1on"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={({ isActive }) => (isActive ? 'active nav-link' : 'inactive nav-link')}
                >
                  LITEPAPER
                </NavLink>
              </li>
              <li className="nav-item dropdown">
                <button 
                  className="btn btn-light"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  ???
                </button>
                <ul className="dropdown-menu dropdown-menu-dark custom-cursor">
                  <li>
                    <button className="dropdown-item disabled" href="#">
                      PHYGITALS ?
                    </button>
                  </li>
                  <li>
                    <button className="dropdown-item disabled" href="#">
                      SHOP ?
                    </button>
                  </li>
                  <li>
                    <button className="dropdown-item disabled" href="#">
                      $CREDS ?
                    </button>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link social"
                  href="https://twitter.com/0xLEG1ON"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ion-icon name="logo-twitter"></ion-icon>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link social"
                  href="https://discord.gg/Dystolabs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ion-icon name="logo-discord"></ion-icon>
                </a>
              </li>
              <li className="nav-item">
              <a
                 
                href='https://blur.io/collection/leg1on'
                target="_blank"
                rel="noopener noreferrer"
                >
                  <img className="nav-link social" src={opensea} alt='Blur' />
              </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
