import React from "react";
import { Link } from "react-router-dom";

function FAQComponent() {
  return (
    <div id="F.A.Q." className="faq text-center py-5">
      <div className="container">
        <div className="row section">
          <div className="col-lg-6">
            <img
              src="img/content/gang.png"
              alt="Gang"
              className="img-fluid d-lg-none"
            />
            <img
              src="img/content/gang.png"
              alt="Gang"
              className="img-fluid liz d-none d-lg-block"
            />
          </div>
          <div className="col-lg-6 bg-text">
            <br />
            <br />
            <h2 className="dystopunks">F.A.Q.</h2>
            <hr />
            <br />
            <h4 className="dystopunks">Wen mint and how much?</h4>
            <p className="lead">
              Q4 of 2023 and it will be a Free Claim for{" "}
              <a
                href="https://opensea.io/collection/encode-key"
                target="_blank"
                rel="noopener noreferrer"
              >
                ENCODE Keys
              </a>{" "}
              and{" "}
              <a
                href="https://blur.io/collection/dystopunks"
                target="_blank"
                rel="noopener noreferrer"
              >
                DystoPunks NFTs
              </a>{" "}
              holders, however some NFTs can be minted if you belong to the
              Allowlist,  If you are interested, please fill out {" "}
              <a
                href="https://forms.gle/jPCXxeMGePxUaEQP9"
                target="_blank"
                rel="noopener noreferrer"
              >
                This Form
              </a>
            </p>
            <br />
            <h4 className="dystopunks">What is the purpose?</h4>
            <p className="lead">
              We have worked together since the beginning with the creation of{" "}
              <Link to="/">WIZ Comics</Link>, therefore we want to do something
              unique allowing the growth of both projects and also as a
              thank you gift to our loyal communities.
            </p>
            <br />
            <h4 className="dystopunks">Will LEG1ON NFTs have utility?</h4>
            <p className="lead">
              Of course, apart from belonging to amazing communities, LEG1ON
              NFTs will be part of a huge Web3 ecosystem with Metaverse and
              Tokenomics in continuous development, please read our{" "}
              <a
                href="https://litepaper.dystopunks.net/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Litepaper
              </a>
            </p>
            <br />
            <a
              href="https://discord.gg/Dystolabs"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-lg btn-primary"
            >
              ENTER DISCORD
            </a>
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQComponent;
