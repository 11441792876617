import React from "react";
import { Link } from "react-router-dom";

function Banner() {
  return (
    <>
    <div className="text-center leg1on d-none d-xxl-block">
      <div className="row">
        <div className="col-md-12">
          <Link to="mint" className="btn btn-lg btn-primary">
          MINT OUT!
          </Link>
        </div>
      </div>
    </div>
    <div className="text-center leg1on-sm d-xxl-none">
      <div className="row">
        <div className="col-md-12">
          <Link to="mint" className="btn btn-lg btn-primary">
            MINT OUT!
          </Link>
        </div>
      </div>
    </div>
    </>
  );
}

export default Banner;
