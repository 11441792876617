import React from "react";

function DevTeam() {
  return (
    <div id="Dev_Team" className="container text-center pt-5">
      <div className="row section">
        <div className="col-lg-6">
          <br />
          <br />
          <h2 className="dystopunks">LEG1ON DEV TEAM</h2>
          <hr />
          <div className="row justify-content-center">
            {renderTeamMember(
              "https://twitter.com/primal_cypher",
              "img/devteam/pr1mal.png",
              "@Pr1mal Cypher",
              "Co-Founder/Art Lead"
            )}
            {renderTeamMember(
              "https://twitter.com/0xDekadente",
              "img/devteam/dekadente.png",
              "@Dekadente",
              "Co-Founder/Main Dev"
            )}
            {renderTeamMember(
              "https://twitter.com/0xVinacho",
              "img/devteam/vinacho.png",
              "@Vinacho",
              "Web3/Solidity Dev"
            )}
            {renderTeamMember(
              "https://twitter.com/0xbr33d",
              "img/devteam/br33d.png",
              "@Br33d",
              "Web3/Solidity Dev"
            )}
            {renderTeamMember(
              "https://twitter.com/keung",
              "img/devteam/keungz.png",
              "@Keungz",
              "Council Member"
            )}
            {renderTeamMember(
              "https://twitter.com/captainkong_eth",
              "img/devteam/kong.png",
              "@Captain Kong",
              "Council Member"
            )}
          </div>
          <br />
          <br />
        </div>
        <div className="col-lg-6">
          <img
            src="img/content/webb.png"
            alt="Webb"
            className="img-fluid webb d-none d-lg-block"
          />
        </div>
      </div>
    </div>
  );
}

function renderTeamMember(twitterLink, imgSrc, name, role) {
  return (
    <div className="col-sm-4">
      <a href={twitterLink} target="_blank" rel="noopener noreferrer">
        <img src={imgSrc} alt={name} className="img-fluid devteam" />
      </a>
      <p>
        <a href={twitterLink} target="_blank" rel="noopener noreferrer">
          {name}
        </a>
        <br />
        <small>{role}</small>
      </p>
    </div>
  );
}

export default DevTeam;
