import './App.css';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './components/Home';
import Mint from './components/Mint';
import ScrollToTop from './components/ScrollToTop';
import Wiz from './components/Wiz';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { WagmiConfig, createConfig, configureChains, mainnet } from 'wagmi'
import { sepolia } from 'viem/chains';
import { publicProvider } from 'wagmi/providers/public'
import { alchemyProvider } from 'wagmi/providers/alchemy'
 
const { publicClient, webSocketPublicClient } = configureChains(
  [mainnet, sepolia],
  [alchemyProvider({ apiKey: '8jcRFmuVtsGTIgHd3zDSy2wvY4pIr8JT' }), publicProvider()],
)
 
const config = createConfig({
  autoConnect: true,
  publicClient,
  webSocketPublicClient,
})

function App() {
  return (
    <WagmiConfig config={config}>
    <BrowserRouter>
    <ScrollToTop />
    <Header />
    <Routes>
      <Route index element={<Home />} />
      <Route path="mint" element={<Mint />} />
      <Route path="wiz" element={<Wiz />} />
    </Routes>
    <Footer />
    </BrowserRouter>
    </WagmiConfig>
  );
}

export default App;
