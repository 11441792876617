import React from "react";
import Leg1onInfo from "./LegionInfo";

function Mint() {
  return (
    <>
      <Leg1onInfo />
    </>
  );
}

export default Mint;
