import React from "react";
import MintInfoCards from "./MintInfoCards";
import MintComp from "./MintComp";

const Leg1onComponent = () => {
  const mintTime = true
  return (
    <div className="container text-center py-5">
      <div className="row section">
        <div className="col-lg-6">
          <br />
          <br />
          <h2 className="dystopunks">TAKE THE LEG1ON PILL</h2>
          <br />
          <p className="lead">
            Take the LEG1ON pill and be part of a digital universe full of
            technology, innovation, gaming, collectibles, comics and high
            quality art.
            <br />
            <br />
            LEG1ON mint has ended! 2077 NFTs have been claimed!
          </p>
          <br />
          {mintTime ? <MintComp />:<MintInfoCards />}
        </div>
        <div className="col-lg-6">
          <br />
          <img src="img/content/blake.png" alt="Blake" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default Leg1onComponent;
