import React from "react";
import SketchesComponent from "./SketchesComponent";
import BannerWiz from "./BannerWiz";
import WizComicsComponent from "./WizComicsComponent";

function Wiz() {
  return (
    <>
      <BannerWiz />
      <WizComicsComponent />
      <SketchesComponent />
    </>
  );
}

export default Wiz;
