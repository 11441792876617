import React, { useEffect, useState } from 'react'
import './styles.css';
import { useLeg1onAvailableDystoToClaim, useLeg1onDystoClaim, usePrepareLeg1onDystoClaim } from '../../generated.ts';
import { useWaitForTransaction } from 'wagmi';

function DystoFreeClaim({ address }) {
  const [ hasDystopunks, setHasDystopunks ] = useState(false)
  const [ dystopunks, setDystopunks] = useState([])
  const [selectedNFTs, setSelectedNFTs] = useState([]);

  const handleCheckboxChange = (id, isChecked) => {
    if (isChecked) {
      setSelectedNFTs(prevSelected => [...prevSelected, id]);
    } else {
      setSelectedNFTs(prevSelected => prevSelected.filter(nftId => nftId !== id));
    }
  };

  useEffect(() => {
    setHasDystopunks(false)
  }, [address])

  const { data: dystopunksHoldingData } = useLeg1onAvailableDystoToClaim({
    args: [address],
  })

  useEffect(() => {
    if (dystopunksHoldingData && Object.keys(dystopunksHoldingData).length === 0) {
      
      setHasDystopunks(false)
      setDystopunks([])
      setSelectedNFTs([])
    } else {
      setDystopunks(dystopunksHoldingData)
      setHasDystopunks(true)
      setSelectedNFTs(dystopunksHoldingData)
    }
  }, [dystopunksHoldingData])
  
  const { config,
    error: prepareError,
    isError: isPrepareError, } = usePrepareLeg1onDystoClaim({
      enabled: hasDystopunks,
      args: [selectedNFTs],
  })

  const { data: mintData, error, isError, isLoading: isMintLoading, write } = useLeg1onDystoClaim(config)

const { isLoading, isSuccess } = useWaitForTransaction({
  hash: mintData?.hash,
})

  // console.log(selectedNFTs)
  // console.log("dystopunks: ", dystopunks)

  if (prepareError || isPrepareError){
    console.log(prepareError || isPrepareError)
  }
  
  if (error) {
    console.log(error)
  }
  if (hasDystopunks)
  return (<>
    <br />
    <div className="free-mint py-4 px-4 mint-item">
      <div className="row">
        <div className="col-md-6">
          <h4 className="strong">DYSTO FREE CLAIM</h4>
        </div>
        <div className="col-md-3">
          <h4 className="free-mint-tag">27 DEC</h4>
        </div>
        <div className="col-md-3">
          <h4 className="free-mint-tag">0.00 ETH</h4>
        </div>
      </div>
      <p className="mint-text">
        DystoPunks Free Claims:
      </p>

      <div className="row">
        {dystopunks?.map((id) => (
          <div className="col-md-2" key={parseInt(id) + 1}>
            <img src={`img/dystopunks/${parseInt(id) + 1}${id <= 99 ? ".gif":".png"}`} alt="DystoPunk" className="img-fluid collection" />
            <div className="form-check">
              <input 
                className="form-check-input" 
                type="checkbox" 
                id={`claim-dysto-${id}`} 
                onChange={(e) => handleCheckboxChange(id, e.target.checked)}
                checked={selectedNFTs.includes(id)}
              />
              <label className="form-check-label" htmlFor={`claim-dysto-${id}`}>Claim</label>
            </div>
          </div>
        ))}
      </div>

      <div className="col-md-12">
      <button className="btn btn-secondary" 
          onClick={() => write?.()}
            disabled={isMintLoading || isLoading || isPrepareError || selectedNFTs?.length < 1}
          >
            {isMintLoading && "AWAITING..."}
            {!isMintLoading && !isLoading && !isSuccess && "MINT"}
            {!isMintLoading && !isLoading && isSuccess && "MINTED!"}
            {isLoading && "MINTING..."}</button>
        </div>
        {isError && <h6>There was an error.</h6>}
    </div>
    </>
  );
}

export default DystoFreeClaim