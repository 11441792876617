import React from "react";

function BannerWiz() {
  return (
    <>
      <div className="text-center wiz-comics d-none d-xxl-block"></div>
      <div className="text-center wiz-sm-comics d-xxl-none"></div>
    </>
  );
}

export default BannerWiz;
