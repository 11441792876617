import React, { useEffect, useState } from 'react'
import './styles.css';
import { useLeg1onFreeClaim, usePrepareLeg1onFreeClaim } from '../../generated.ts';
import { useWaitForTransaction } from 'wagmi';
import freeClaimVerifier from '../../helpers/merkleVerifyClaimlist.js';

function FreeMint({address}) {
  const [ hasFreeMint, setHasFreeMint] = useState(false)
  const [proof, setProof] = useState(null)
  const {
    config,
    error: prepareError,
    isError: isPrepareError,
    } = usePrepareLeg1onFreeClaim({
        enabled: hasFreeMint,
        args: [proof],
    })

  const { data: mintData, error: mintError, isError, isLoading: isMintLoading, write } = useLeg1onFreeClaim(config)

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: mintData?.hash,
  })
  
  if (isError) {
    console.log(mintError)
  }

  if (prepareError) {
    console.log("Error with free claim")
  }

  useEffect(() => {
    const hasFreeClaim = freeClaimVerifier(address)
    if (hasFreeClaim !== "No whitelist found.") {
      setProof(hasFreeClaim)
      setHasFreeMint(true)
    } else {
      setHasFreeMint(false)
      setProof(null)
    }
  }, [address])
  
  
  if (hasFreeMint && !isPrepareError)
  return (<>
  <br />
    <div className="free-mint py-4 px-4 mint-item">
      <div className="row">
      <div className="col-md-6">
          <h4 className="strong">FREE MINT</h4>
        </div>
        <div className="col-md-3">
          <h4 className="free-mint-tag">27 DEC</h4>
        </div>
        <div className="col-md-3">
          <h4 className="free-mint-tag">0.00 ETH</h4>
        </div>
      </div>
      <p className="mint-text">
      Congrats! You have a Free Mint:
      </p>
      <div className="col-md-12">
      <button 
            className={`btn btn-secondary`}
            onClick={() => write?.()}
            disabled={isMintLoading || isLoading || isPrepareError}
          >
            {isMintLoading && "AWAITING..."}
            {!isMintLoading && !isLoading && !isSuccess && "MINT"}
            {!isMintLoading && !isLoading && isSuccess && "MINTED!"}
            {isLoading && "MINTING..."}
          </button>
      </div>
      {isError && <h6>There was an error.</h6>}
    </div>
    </>
  );
}

export default FreeMint