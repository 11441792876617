import {
  useNetwork,
  useChainId,
  useContractRead,
  UseContractReadConfig,
  useContractWrite,
  Address,
  UseContractWriteConfig,
  usePrepareContractWrite,
  UsePrepareContractWriteConfig,
  useContractEvent,
  UseContractEventConfig,
} from 'wagmi'
import {
  ReadContractResult,
  WriteContractMode,
  PrepareWriteContractResult,
} from 'wagmi/actions'

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dystopunks
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export const dystopunksABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: 'baseTokenURI', internalType: 'string', type: 'string' },
      { name: 'baseContractURI', internalType: 'string', type: 'string' },
    ],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'owner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'approved',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'tokenId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'owner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'operator',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      { name: 'approved', internalType: 'bool', type: 'bool', indexed: false },
    ],
    name: 'ApprovalForAll',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'previousOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'newOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'OwnershipTransferred',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      {
        name: 'tokenId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'MAX_SPUNKS',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_to', internalType: 'address', type: 'address' },
      { name: '_NumberOfPunks', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'authClaim',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'calculatePrice',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'claimDystoPunk',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'contractURI',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'getApproved',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'numDystoPunks', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'getDystoPunk',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'hasSaleStarted',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'operator', internalType: 'address', type: 'address' },
    ],
    name: 'isApprovedForAll',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_to', internalType: 'address', type: 'address' }],
    name: 'isAuthClaim',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'ownerOf',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'pauseSale',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'numDystoPunks', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'reserveAirdrop',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'safeTransferFrom',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'safeTransferFrom',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'operator', internalType: 'address', type: 'address' },
      { name: 'approved', internalType: 'bool', type: 'bool' },
    ],
    name: 'setApprovalForAll',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'baseURI', internalType: 'string', type: 'string' }],
    name: 'setBaseURI',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'startSale',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'index', internalType: 'uint256', type: 'uint256' }],
    name: 'tokenByIndex',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'index', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'tokenOfOwnerByIndex',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'tokenURI',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_owner', internalType: 'address', type: 'address' }],
    name: 'tokensOfOwner',
    outputs: [{ name: '', internalType: 'uint256[]', type: 'uint256[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [],
    name: 'withdrawAll',
    outputs: [],
  },
] as const

/**
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export const dystopunksAddress = {
  1: '0xbEA8123277142dE42571f1fAc045225a1D347977',
  11155111: '0xb1BEE62bFcC178276956f0D0128F421D253eEED1',
} as const

/**
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export const dystopunksConfig = {
  address: dystopunksAddress,
  abi: dystopunksABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// DystopunksVx
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export const dystopunksVxABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [{ name: 'baseTokenURI', internalType: 'string', type: 'string' }],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'owner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'approved',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'tokenId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'owner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'operator',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      { name: 'approved', internalType: 'bool', type: 'bool', indexed: false },
    ],
    name: 'ApprovalForAll',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'previousOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'newOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'OwnershipTransferred',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      {
        name: 'tokenId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DystoAddress',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'ids', internalType: 'uint256[]', type: 'uint256[]' }],
    name: 'DystosClaim',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'MAX_PUNKS',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'RESERVED_PUNKS',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'ownwer', internalType: 'address', type: 'address' }],
    name: 'availableDystoToClaim',
    outputs: [{ name: '', internalType: 'uint256[]', type: 'uint256[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'ownwer', internalType: 'address', type: 'address' }],
    name: 'availableToClaim',
    outputs: [{ name: '', internalType: 'uint256[]', type: 'uint256[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'id', internalType: 'uint256', type: 'uint256' }],
    name: 'checkDystoToClaim',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'claimedDysto',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'getApproved',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'hasPrivateSaleStarted',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'hasSaleStarted',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'operator', internalType: 'address', type: 'address' },
    ],
    name: 'isApprovedForAll',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [{ name: 'numVX', internalType: 'uint256', type: 'uint256' }],
    name: 'mintAllowList',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'mintClaimList',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [{ name: 'numVX', internalType: 'uint8', type: 'uint8' }],
    name: 'mintWhiteList',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'minted',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'mintfreeList',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'addr', internalType: 'address', type: 'address' }],
    name: 'numAllowMint',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'addr', internalType: 'address', type: 'address' }],
    name: 'numFreeMint',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'addr', internalType: 'address', type: 'address' }],
    name: 'numWhiteMint',
    outputs: [{ name: '', internalType: 'uint8', type: 'uint8' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'ownerOf',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'pausePrivateSale',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'pauseSale',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [{ name: 'numVX', internalType: 'uint256', type: 'uint256' }],
    name: 'publicMint',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'numVX', internalType: 'uint256', type: 'uint256' }],
    name: 'reserveAirdrop',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'safeTransferFrom',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'safeTransferFrom',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'addresses', internalType: 'address[]', type: 'address[]' },
      { name: 'num', internalType: 'uint256[]', type: 'uint256[]' },
    ],
    name: 'setAllowList',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'operator', internalType: 'address', type: 'address' },
      { name: 'approved', internalType: 'bool', type: 'bool' },
    ],
    name: 'setApprovalForAll',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'baseURI', internalType: 'string', type: 'string' }],
    name: 'setBaseURI',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'addresses', internalType: 'address[]', type: 'address[]' },
    ],
    name: 'setClaimMint',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'addresses', internalType: 'address[]', type: 'address[]' },
      { name: 'num', internalType: 'uint8[]', type: 'uint8[]' },
    ],
    name: 'setFreeList',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'addresses', internalType: 'address[]', type: 'address[]' },
    ],
    name: 'setWhiteList',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'startPrivateSale',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'startSale',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '_tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'tokenURI',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [],
    name: 'withdrawAll',
    outputs: [],
  },
] as const

/**
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export const dystopunksVxAddress = {
  1: '0xf91523Bc0ffA151ABd971f1b11D2567d4167DB3E',
  11155111: '0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797',
} as const

/**
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export const dystopunksVxConfig = {
  address: dystopunksVxAddress,
  abi: dystopunksVxABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// WagmiMintExample
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 */
export const wagmiMintExampleABI = [
  { stateMutability: 'nonpayable', type: 'constructor', inputs: [] },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'owner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'approved',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'tokenId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'owner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'operator',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      { name: 'approved', internalType: 'bool', type: 'bool', indexed: false },
    ],
    name: 'ApprovalForAll',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      {
        name: 'tokenId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'getApproved',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'operator', internalType: 'address', type: 'address' },
    ],
    name: 'isApprovedForAll',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'mint',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'mint',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'ownerOf',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'safeTransferFrom',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'safeTransferFrom',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'operator', internalType: 'address', type: 'address' },
      { name: 'approved', internalType: 'bool', type: 'bool' },
    ],
    name: 'setApprovalForAll',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'tokenURI',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [],
  },
] as const

/**
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 */
export const wagmiMintExampleAddress = {
  1: '0xFBA3912Ca04dd458c843e2EE08967fC04f3579c2',
  5: '0xFBA3912Ca04dd458c843e2EE08967fC04f3579c2',
} as const

/**
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 */
export const wagmiMintExampleConfig = {
  address: wagmiMintExampleAddress,
  abi: wagmiMintExampleABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// leg1on
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export const leg1onABI = [
  {
    stateMutability: 'nonpayable',
    type: 'constructor',
    inputs: [
      { name: 'whitelistRoot_', internalType: 'bytes32', type: 'bytes32' },
      { name: 'claimRoot_', internalType: 'bytes32', type: 'bytes32' },
    ],
  },
  { type: 'error', inputs: [], name: 'ApprovalCallerNotOwnerNorApproved' },
  { type: 'error', inputs: [], name: 'ApprovalQueryForNonexistentToken' },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [],
  },
  { type: 'error', inputs: [], name: 'BalanceQueryForZeroAddress' },
  { type: 'error', inputs: [], name: 'InvalidQueryRange' },
  { type: 'error', inputs: [], name: 'MintERC2309QuantityExceedsLimit' },
  { type: 'error', inputs: [], name: 'MintToZeroAddress' },
  { type: 'error', inputs: [], name: 'MintZeroQuantity' },
  { type: 'error', inputs: [], name: 'OwnerQueryForNonexistentToken' },
  { type: 'error', inputs: [], name: 'OwnershipNotInitializedForExtraData' },
  { type: 'error', inputs: [], name: 'TransferCallerNotOwnerNorApproved' },
  { type: 'error', inputs: [], name: 'TransferFromIncorrectOwner' },
  { type: 'error', inputs: [], name: 'TransferToNonERC721ReceiverImplementer' },
  { type: 'error', inputs: [], name: 'TransferToZeroAddress' },
  { type: 'error', inputs: [], name: 'URIQueryForNonexistentToken' },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'owner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'approved',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'tokenId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'owner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'operator',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      { name: 'approved', internalType: 'bool', type: 'bool', indexed: false },
    ],
    name: 'ApprovalForAll',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'fromTokenId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
      {
        name: 'toTokenId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
    ],
    name: 'ConsecutiveTransfer',
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'ids', internalType: 'uint256[]', type: 'uint256[]' }],
    name: 'dystoClaim',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'claimProof', internalType: 'bytes32[]', type: 'bytes32[]' },
    ],
    name: 'freeClaim',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'ids', internalType: 'uint256[]', type: 'uint256[]' }],
    name: 'keyClaim',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [{ name: '_mintAmount', internalType: 'uint256', type: 'uint256' }],
    name: 'mintVXList',
    outputs: [],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'previousOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'newOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'OwnershipTransferred',
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [{ name: '_mintAmount', internalType: 'uint256', type: 'uint256' }],
    name: 'publicMint',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_mintAmount', internalType: 'uint256', type: 'uint256' },
      { name: 'addr', internalType: 'address', type: 'address' },
    ],
    name: 'reserveAirdrop',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'safeTransferFrom',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'safeTransferFrom',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'operator', internalType: 'address', type: 'address' },
      { name: 'approved', internalType: 'bool', type: 'bool' },
    ],
    name: 'setApprovalForAll',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'baseURI', internalType: 'string', type: 'string' }],
    name: 'setBaseURI',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: '_claimRoot', internalType: 'bytes32', type: 'bytes32' }],
    name: 'setClaimRoot',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_maxCollectionSize', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'setCollectionSize',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'privateSale', internalType: 'bool', type: 'bool' },
      { name: 'publicSaleStart', internalType: 'bool', type: 'bool' },
    ],
    name: 'setupSaleInfo',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: '_whitelistRoot', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'setWhitelistRoot',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'num', internalType: 'uint256', type: 'uint256' }],
    name: 'stopSale',
    outputs: [],
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      {
        name: 'tokenId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'whitelistProof', internalType: 'bytes32[]', type: 'bytes32[]' },
      { name: '_mintAmount', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'whitelistMint',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'withdraw',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'availableDystoToClaim',
    outputs: [{ name: '', internalType: 'uint256[]', type: 'uint256[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'availableKeyClaim',
    outputs: [{ name: '', internalType: 'uint256[]', type: 'uint256[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'id', internalType: 'uint256', type: 'uint256' }],
    name: 'checkDystoToClaim',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'id', internalType: 'uint256', type: 'uint256' }],
    name: 'checkKeyClaim',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'address', type: 'address' }],
    name: 'claimed',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'claimedDysto',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
    name: 'claimedKey',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'claimRoot',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'collectionSize',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'DystoAddress',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'EncodeAddress',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'explicitOwnershipOf',
    outputs: [
      {
        name: 'ownership',
        internalType: 'struct IERC721A.TokenOwnership',
        type: 'tuple',
        components: [
          { name: 'addr', internalType: 'address', type: 'address' },
          { name: 'startTimestamp', internalType: 'uint64', type: 'uint64' },
          { name: 'burned', internalType: 'bool', type: 'bool' },
          { name: 'extraData', internalType: 'uint24', type: 'uint24' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'tokenIds', internalType: 'uint256[]', type: 'uint256[]' },
    ],
    name: 'explicitOwnershipsOf',
    outputs: [
      {
        name: '',
        internalType: 'struct IERC721A.TokenOwnership[]',
        type: 'tuple[]',
        components: [
          { name: 'addr', internalType: 'address', type: 'address' },
          { name: 'startTimestamp', internalType: 'uint64', type: 'uint64' },
          { name: 'burned', internalType: 'bool', type: 'bool' },
          { name: 'extraData', internalType: 'uint24', type: 'uint24' },
        ],
      },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'getApproved',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'operator', internalType: 'address', type: 'address' },
    ],
    name: 'isApprovedForAll',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'numberMinted',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'ownerOf',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'publicPrice',
    outputs: [{ name: '', internalType: 'uint64', type: 'uint64' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'reserved',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'saleConfig',
    outputs: [
      { name: 'privateSale', internalType: 'bool', type: 'bool' },
      { name: 'publicSaleStart', internalType: 'bool', type: 'bool' },
    ],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'stoped',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'tokensOfOwner',
    outputs: [{ name: '', internalType: 'uint256[]', type: 'uint256[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'start', internalType: 'uint256', type: 'uint256' },
      { name: 'stop', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'tokensOfOwnerIn',
    outputs: [{ name: '', internalType: 'uint256[]', type: 'uint256[]' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'tokenURI',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'VXAddress',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'whitelistPrice',
    outputs: [{ name: '', internalType: 'uint64', type: 'uint64' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'whitelistRoot',
    outputs: [{ name: '', internalType: 'bytes32', type: 'bytes32' }],
  },
] as const

/**
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export const leg1onAddress = {
  1: '0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C',
  11155111: '0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7',
} as const

/**
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export const leg1onConfig = { address: leg1onAddress, abi: leg1onABI } as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// React
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksABI}__.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof dystopunksABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    ...config,
  } as UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"MAX_SPUNKS"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksMaxSpunks<
  TFunctionName extends 'MAX_SPUNKS',
  TSelectData = ReadContractResult<typeof dystopunksABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'MAX_SPUNKS',
    ...config,
  } as UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"balanceOf"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof dystopunksABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"calculatePrice"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksCalculatePrice<
  TFunctionName extends 'calculatePrice',
  TSelectData = ReadContractResult<typeof dystopunksABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'calculatePrice',
    ...config,
  } as UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"contractURI"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksContractUri<
  TFunctionName extends 'contractURI',
  TSelectData = ReadContractResult<typeof dystopunksABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'contractURI',
    ...config,
  } as UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"getApproved"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksGetApproved<
  TFunctionName extends 'getApproved',
  TSelectData = ReadContractResult<typeof dystopunksABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'getApproved',
    ...config,
  } as UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"hasSaleStarted"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksHasSaleStarted<
  TFunctionName extends 'hasSaleStarted',
  TSelectData = ReadContractResult<typeof dystopunksABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'hasSaleStarted',
    ...config,
  } as UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"isApprovedForAll"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksIsApprovedForAll<
  TFunctionName extends 'isApprovedForAll',
  TSelectData = ReadContractResult<typeof dystopunksABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'isApprovedForAll',
    ...config,
  } as UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"isAuthClaim"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksIsAuthClaim<
  TFunctionName extends 'isAuthClaim',
  TSelectData = ReadContractResult<typeof dystopunksABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'isAuthClaim',
    ...config,
  } as UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"name"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof dystopunksABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"owner"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksOwner<
  TFunctionName extends 'owner',
  TSelectData = ReadContractResult<typeof dystopunksABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'owner',
    ...config,
  } as UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"ownerOf"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksOwnerOf<
  TFunctionName extends 'ownerOf',
  TSelectData = ReadContractResult<typeof dystopunksABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'ownerOf',
    ...config,
  } as UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"supportsInterface"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksSupportsInterface<
  TFunctionName extends 'supportsInterface',
  TSelectData = ReadContractResult<typeof dystopunksABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"symbol"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksSymbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<typeof dystopunksABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"tokenByIndex"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksTokenByIndex<
  TFunctionName extends 'tokenByIndex',
  TSelectData = ReadContractResult<typeof dystopunksABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'tokenByIndex',
    ...config,
  } as UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"tokenOfOwnerByIndex"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksTokenOfOwnerByIndex<
  TFunctionName extends 'tokenOfOwnerByIndex',
  TSelectData = ReadContractResult<typeof dystopunksABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'tokenOfOwnerByIndex',
    ...config,
  } as UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"tokenURI"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksTokenUri<
  TFunctionName extends 'tokenURI',
  TSelectData = ReadContractResult<typeof dystopunksABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'tokenURI',
    ...config,
  } as UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"tokensOfOwner"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksTokensOfOwner<
  TFunctionName extends 'tokensOfOwner',
  TSelectData = ReadContractResult<typeof dystopunksABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'tokensOfOwner',
    ...config,
  } as UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"totalSupply"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksTotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof dystopunksABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<typeof dystopunksABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksABI}__.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof dystopunksABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksABI, TFunctionName, TMode>({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"approve"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksApprove<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksABI,
          'approve'
        >['request']['abi'],
        'approve',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'approve' }
    : UseContractWriteConfig<typeof dystopunksABI, 'approve', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'approve'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksABI, 'approve', TMode>({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'approve',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"authClaim"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksAuthClaim<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksABI,
          'authClaim'
        >['request']['abi'],
        'authClaim',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'authClaim' }
    : UseContractWriteConfig<typeof dystopunksABI, 'authClaim', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'authClaim'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksABI, 'authClaim', TMode>({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'authClaim',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"claimDystoPunk"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksClaimDystoPunk<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksABI,
          'claimDystoPunk'
        >['request']['abi'],
        'claimDystoPunk',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'claimDystoPunk'
      }
    : UseContractWriteConfig<typeof dystopunksABI, 'claimDystoPunk', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'claimDystoPunk'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksABI, 'claimDystoPunk', TMode>({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'claimDystoPunk',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"getDystoPunk"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksGetDystoPunk<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksABI,
          'getDystoPunk'
        >['request']['abi'],
        'getDystoPunk',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'getDystoPunk'
      }
    : UseContractWriteConfig<typeof dystopunksABI, 'getDystoPunk', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'getDystoPunk'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksABI, 'getDystoPunk', TMode>({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'getDystoPunk',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"pauseSale"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksPauseSale<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksABI,
          'pauseSale'
        >['request']['abi'],
        'pauseSale',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'pauseSale' }
    : UseContractWriteConfig<typeof dystopunksABI, 'pauseSale', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'pauseSale'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksABI, 'pauseSale', TMode>({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'pauseSale',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"renounceOwnership"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksRenounceOwnership<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksABI,
          'renounceOwnership'
        >['request']['abi'],
        'renounceOwnership',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'renounceOwnership'
      }
    : UseContractWriteConfig<
        typeof dystopunksABI,
        'renounceOwnership',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'renounceOwnership'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksABI, 'renounceOwnership', TMode>({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'renounceOwnership',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"reserveAirdrop"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksReserveAirdrop<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksABI,
          'reserveAirdrop'
        >['request']['abi'],
        'reserveAirdrop',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'reserveAirdrop'
      }
    : UseContractWriteConfig<typeof dystopunksABI, 'reserveAirdrop', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'reserveAirdrop'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksABI, 'reserveAirdrop', TMode>({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'reserveAirdrop',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"safeTransferFrom"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksSafeTransferFrom<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksABI,
          'safeTransferFrom'
        >['request']['abi'],
        'safeTransferFrom',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'safeTransferFrom'
      }
    : UseContractWriteConfig<
        typeof dystopunksABI,
        'safeTransferFrom',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'safeTransferFrom'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksABI, 'safeTransferFrom', TMode>({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'safeTransferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"setApprovalForAll"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksSetApprovalForAll<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksABI,
          'setApprovalForAll'
        >['request']['abi'],
        'setApprovalForAll',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'setApprovalForAll'
      }
    : UseContractWriteConfig<
        typeof dystopunksABI,
        'setApprovalForAll',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setApprovalForAll'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksABI, 'setApprovalForAll', TMode>({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'setApprovalForAll',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"setBaseURI"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksSetBaseUri<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksABI,
          'setBaseURI'
        >['request']['abi'],
        'setBaseURI',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setBaseURI' }
    : UseContractWriteConfig<typeof dystopunksABI, 'setBaseURI', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setBaseURI'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksABI, 'setBaseURI', TMode>({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'setBaseURI',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"startSale"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksStartSale<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksABI,
          'startSale'
        >['request']['abi'],
        'startSale',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'startSale' }
    : UseContractWriteConfig<typeof dystopunksABI, 'startSale', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'startSale'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksABI, 'startSale', TMode>({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'startSale',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"transferFrom"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksTransferFrom<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksABI,
          'transferFrom'
        >['request']['abi'],
        'transferFrom',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'transferFrom'
      }
    : UseContractWriteConfig<typeof dystopunksABI, 'transferFrom', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'transferFrom'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksABI, 'transferFrom', TMode>({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'transferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"transferOwnership"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksTransferOwnership<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksABI,
          'transferOwnership'
        >['request']['abi'],
        'transferOwnership',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'transferOwnership'
      }
    : UseContractWriteConfig<
        typeof dystopunksABI,
        'transferOwnership',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'transferOwnership'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksABI, 'transferOwnership', TMode>({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'transferOwnership',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"withdrawAll"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksWithdrawAll<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksABI,
          'withdrawAll'
        >['request']['abi'],
        'withdrawAll',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'withdrawAll'
      }
    : UseContractWriteConfig<typeof dystopunksABI, 'withdrawAll', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'withdrawAll'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksABI, 'withdrawAll', TMode>({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'withdrawAll',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksABI}__.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function usePrepareDystopunksWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksABI, TFunctionName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"approve"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function usePrepareDystopunksApprove(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksABI, 'approve'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksABI, 'approve'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"authClaim"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function usePrepareDystopunksAuthClaim(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksABI, 'authClaim'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'authClaim',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksABI, 'authClaim'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"claimDystoPunk"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function usePrepareDystopunksClaimDystoPunk(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksABI, 'claimDystoPunk'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'claimDystoPunk',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksABI, 'claimDystoPunk'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"getDystoPunk"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function usePrepareDystopunksGetDystoPunk(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksABI, 'getDystoPunk'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'getDystoPunk',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksABI, 'getDystoPunk'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"pauseSale"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function usePrepareDystopunksPauseSale(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksABI, 'pauseSale'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'pauseSale',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksABI, 'pauseSale'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"renounceOwnership"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function usePrepareDystopunksRenounceOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksABI, 'renounceOwnership'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'renounceOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksABI, 'renounceOwnership'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"reserveAirdrop"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function usePrepareDystopunksReserveAirdrop(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksABI, 'reserveAirdrop'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'reserveAirdrop',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksABI, 'reserveAirdrop'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"safeTransferFrom"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function usePrepareDystopunksSafeTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksABI, 'safeTransferFrom'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'safeTransferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksABI, 'safeTransferFrom'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"setApprovalForAll"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function usePrepareDystopunksSetApprovalForAll(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksABI, 'setApprovalForAll'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'setApprovalForAll',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksABI, 'setApprovalForAll'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"setBaseURI"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function usePrepareDystopunksSetBaseUri(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksABI, 'setBaseURI'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'setBaseURI',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksABI, 'setBaseURI'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"startSale"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function usePrepareDystopunksStartSale(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksABI, 'startSale'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'startSale',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksABI, 'startSale'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"transferFrom"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function usePrepareDystopunksTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksABI, 'transferFrom'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksABI, 'transferFrom'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"transferOwnership"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function usePrepareDystopunksTransferOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksABI, 'transferOwnership'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'transferOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksABI, 'transferOwnership'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksABI}__ and `functionName` set to `"withdrawAll"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function usePrepareDystopunksWithdrawAll(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksABI, 'withdrawAll'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    functionName: 'withdrawAll',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksABI, 'withdrawAll'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link dystopunksABI}__.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof dystopunksABI, TEventName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractEvent({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    ...config,
  } as UseContractEventConfig<typeof dystopunksABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link dystopunksABI}__ and `eventName` set to `"Approval"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksApprovalEvent(
  config: Omit<
    UseContractEventConfig<typeof dystopunksABI, 'Approval'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractEvent({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    eventName: 'Approval',
    ...config,
  } as UseContractEventConfig<typeof dystopunksABI, 'Approval'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link dystopunksABI}__ and `eventName` set to `"ApprovalForAll"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksApprovalForAllEvent(
  config: Omit<
    UseContractEventConfig<typeof dystopunksABI, 'ApprovalForAll'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractEvent({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    eventName: 'ApprovalForAll',
    ...config,
  } as UseContractEventConfig<typeof dystopunksABI, 'ApprovalForAll'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link dystopunksABI}__ and `eventName` set to `"OwnershipTransferred"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksOwnershipTransferredEvent(
  config: Omit<
    UseContractEventConfig<typeof dystopunksABI, 'OwnershipTransferred'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractEvent({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    eventName: 'OwnershipTransferred',
    ...config,
  } as UseContractEventConfig<typeof dystopunksABI, 'OwnershipTransferred'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link dystopunksABI}__ and `eventName` set to `"Transfer"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xbea8123277142de42571f1fac045225a1d347977)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xb1BEE62bFcC178276956f0D0128F421D253eEED1)
 */
export function useDystopunksTransferEvent(
  config: Omit<
    UseContractEventConfig<typeof dystopunksABI, 'Transfer'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof dystopunksAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractEvent({
    abi: dystopunksABI,
    address: dystopunksAddress[chainId as keyof typeof dystopunksAddress],
    eventName: 'Transfer',
    ...config,
  } as UseContractEventConfig<typeof dystopunksABI, 'Transfer'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksVxABI}__.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof dystopunksVxABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksVxABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    ...config,
  } as UseContractReadConfig<
    typeof dystopunksVxABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"DystoAddress"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxDystoAddress<
  TFunctionName extends 'DystoAddress',
  TSelectData = ReadContractResult<typeof dystopunksVxABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksVxABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'DystoAddress',
    ...config,
  } as UseContractReadConfig<
    typeof dystopunksVxABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"MAX_PUNKS"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxMaxPunks<
  TFunctionName extends 'MAX_PUNKS',
  TSelectData = ReadContractResult<typeof dystopunksVxABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksVxABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'MAX_PUNKS',
    ...config,
  } as UseContractReadConfig<
    typeof dystopunksVxABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"RESERVED_PUNKS"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxReservedPunks<
  TFunctionName extends 'RESERVED_PUNKS',
  TSelectData = ReadContractResult<typeof dystopunksVxABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksVxABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'RESERVED_PUNKS',
    ...config,
  } as UseContractReadConfig<
    typeof dystopunksVxABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"availableDystoToClaim"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxAvailableDystoToClaim<
  TFunctionName extends 'availableDystoToClaim',
  TSelectData = ReadContractResult<typeof dystopunksVxABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksVxABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'availableDystoToClaim',
    ...config,
  } as UseContractReadConfig<
    typeof dystopunksVxABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"availableToClaim"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxAvailableToClaim<
  TFunctionName extends 'availableToClaim',
  TSelectData = ReadContractResult<typeof dystopunksVxABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksVxABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'availableToClaim',
    ...config,
  } as UseContractReadConfig<
    typeof dystopunksVxABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"balanceOf"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof dystopunksVxABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksVxABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<
    typeof dystopunksVxABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"checkDystoToClaim"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxCheckDystoToClaim<
  TFunctionName extends 'checkDystoToClaim',
  TSelectData = ReadContractResult<typeof dystopunksVxABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksVxABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'checkDystoToClaim',
    ...config,
  } as UseContractReadConfig<
    typeof dystopunksVxABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"claimedDysto"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxClaimedDysto<
  TFunctionName extends 'claimedDysto',
  TSelectData = ReadContractResult<typeof dystopunksVxABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksVxABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'claimedDysto',
    ...config,
  } as UseContractReadConfig<
    typeof dystopunksVxABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"getApproved"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxGetApproved<
  TFunctionName extends 'getApproved',
  TSelectData = ReadContractResult<typeof dystopunksVxABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksVxABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'getApproved',
    ...config,
  } as UseContractReadConfig<
    typeof dystopunksVxABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"hasPrivateSaleStarted"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxHasPrivateSaleStarted<
  TFunctionName extends 'hasPrivateSaleStarted',
  TSelectData = ReadContractResult<typeof dystopunksVxABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksVxABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'hasPrivateSaleStarted',
    ...config,
  } as UseContractReadConfig<
    typeof dystopunksVxABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"hasSaleStarted"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxHasSaleStarted<
  TFunctionName extends 'hasSaleStarted',
  TSelectData = ReadContractResult<typeof dystopunksVxABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksVxABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'hasSaleStarted',
    ...config,
  } as UseContractReadConfig<
    typeof dystopunksVxABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"isApprovedForAll"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxIsApprovedForAll<
  TFunctionName extends 'isApprovedForAll',
  TSelectData = ReadContractResult<typeof dystopunksVxABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksVxABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'isApprovedForAll',
    ...config,
  } as UseContractReadConfig<
    typeof dystopunksVxABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"minted"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxMinted<
  TFunctionName extends 'minted',
  TSelectData = ReadContractResult<typeof dystopunksVxABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksVxABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'minted',
    ...config,
  } as UseContractReadConfig<
    typeof dystopunksVxABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"name"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof dystopunksVxABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksVxABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<
    typeof dystopunksVxABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"numAllowMint"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxNumAllowMint<
  TFunctionName extends 'numAllowMint',
  TSelectData = ReadContractResult<typeof dystopunksVxABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksVxABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'numAllowMint',
    ...config,
  } as UseContractReadConfig<
    typeof dystopunksVxABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"numFreeMint"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxNumFreeMint<
  TFunctionName extends 'numFreeMint',
  TSelectData = ReadContractResult<typeof dystopunksVxABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksVxABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'numFreeMint',
    ...config,
  } as UseContractReadConfig<
    typeof dystopunksVxABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"numWhiteMint"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxNumWhiteMint<
  TFunctionName extends 'numWhiteMint',
  TSelectData = ReadContractResult<typeof dystopunksVxABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksVxABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'numWhiteMint',
    ...config,
  } as UseContractReadConfig<
    typeof dystopunksVxABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"owner"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxOwner<
  TFunctionName extends 'owner',
  TSelectData = ReadContractResult<typeof dystopunksVxABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksVxABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'owner',
    ...config,
  } as UseContractReadConfig<
    typeof dystopunksVxABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"ownerOf"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxOwnerOf<
  TFunctionName extends 'ownerOf',
  TSelectData = ReadContractResult<typeof dystopunksVxABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksVxABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'ownerOf',
    ...config,
  } as UseContractReadConfig<
    typeof dystopunksVxABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"supportsInterface"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxSupportsInterface<
  TFunctionName extends 'supportsInterface',
  TSelectData = ReadContractResult<typeof dystopunksVxABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksVxABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<
    typeof dystopunksVxABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"symbol"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxSymbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<typeof dystopunksVxABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksVxABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<
    typeof dystopunksVxABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"tokenURI"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxTokenUri<
  TFunctionName extends 'tokenURI',
  TSelectData = ReadContractResult<typeof dystopunksVxABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof dystopunksVxABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'tokenURI',
    ...config,
  } as UseContractReadConfig<
    typeof dystopunksVxABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksVxAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksVxABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof dystopunksVxABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksVxABI, TFunctionName, TMode>({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"DystosClaim"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxDystosClaim<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksVxAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksVxABI,
          'DystosClaim'
        >['request']['abi'],
        'DystosClaim',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'DystosClaim'
      }
    : UseContractWriteConfig<typeof dystopunksVxABI, 'DystosClaim', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'DystosClaim'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksVxABI, 'DystosClaim', TMode>({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'DystosClaim',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"approve"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxApprove<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksVxAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksVxABI,
          'approve'
        >['request']['abi'],
        'approve',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'approve' }
    : UseContractWriteConfig<typeof dystopunksVxABI, 'approve', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'approve'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksVxABI, 'approve', TMode>({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'approve',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"mintAllowList"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxMintAllowList<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksVxAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksVxABI,
          'mintAllowList'
        >['request']['abi'],
        'mintAllowList',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'mintAllowList'
      }
    : UseContractWriteConfig<typeof dystopunksVxABI, 'mintAllowList', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'mintAllowList'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksVxABI, 'mintAllowList', TMode>({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'mintAllowList',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"mintClaimList"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxMintClaimList<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksVxAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksVxABI,
          'mintClaimList'
        >['request']['abi'],
        'mintClaimList',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'mintClaimList'
      }
    : UseContractWriteConfig<typeof dystopunksVxABI, 'mintClaimList', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'mintClaimList'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksVxABI, 'mintClaimList', TMode>({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'mintClaimList',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"mintWhiteList"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxMintWhiteList<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksVxAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksVxABI,
          'mintWhiteList'
        >['request']['abi'],
        'mintWhiteList',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'mintWhiteList'
      }
    : UseContractWriteConfig<typeof dystopunksVxABI, 'mintWhiteList', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'mintWhiteList'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksVxABI, 'mintWhiteList', TMode>({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'mintWhiteList',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"mintfreeList"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxMintfreeList<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksVxAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksVxABI,
          'mintfreeList'
        >['request']['abi'],
        'mintfreeList',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'mintfreeList'
      }
    : UseContractWriteConfig<typeof dystopunksVxABI, 'mintfreeList', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'mintfreeList'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksVxABI, 'mintfreeList', TMode>({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'mintfreeList',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"pausePrivateSale"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxPausePrivateSale<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksVxAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksVxABI,
          'pausePrivateSale'
        >['request']['abi'],
        'pausePrivateSale',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'pausePrivateSale'
      }
    : UseContractWriteConfig<
        typeof dystopunksVxABI,
        'pausePrivateSale',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'pausePrivateSale'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksVxABI, 'pausePrivateSale', TMode>({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'pausePrivateSale',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"pauseSale"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxPauseSale<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksVxAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksVxABI,
          'pauseSale'
        >['request']['abi'],
        'pauseSale',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'pauseSale' }
    : UseContractWriteConfig<typeof dystopunksVxABI, 'pauseSale', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'pauseSale'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksVxABI, 'pauseSale', TMode>({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'pauseSale',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"publicMint"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxPublicMint<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksVxAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksVxABI,
          'publicMint'
        >['request']['abi'],
        'publicMint',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'publicMint' }
    : UseContractWriteConfig<typeof dystopunksVxABI, 'publicMint', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'publicMint'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksVxABI, 'publicMint', TMode>({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'publicMint',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"renounceOwnership"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxRenounceOwnership<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksVxAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksVxABI,
          'renounceOwnership'
        >['request']['abi'],
        'renounceOwnership',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'renounceOwnership'
      }
    : UseContractWriteConfig<
        typeof dystopunksVxABI,
        'renounceOwnership',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'renounceOwnership'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksVxABI, 'renounceOwnership', TMode>({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'renounceOwnership',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"reserveAirdrop"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxReserveAirdrop<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksVxAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksVxABI,
          'reserveAirdrop'
        >['request']['abi'],
        'reserveAirdrop',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'reserveAirdrop'
      }
    : UseContractWriteConfig<
        typeof dystopunksVxABI,
        'reserveAirdrop',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'reserveAirdrop'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksVxABI, 'reserveAirdrop', TMode>({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'reserveAirdrop',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"safeTransferFrom"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxSafeTransferFrom<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksVxAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksVxABI,
          'safeTransferFrom'
        >['request']['abi'],
        'safeTransferFrom',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'safeTransferFrom'
      }
    : UseContractWriteConfig<
        typeof dystopunksVxABI,
        'safeTransferFrom',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'safeTransferFrom'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksVxABI, 'safeTransferFrom', TMode>({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'safeTransferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"setAllowList"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxSetAllowList<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksVxAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksVxABI,
          'setAllowList'
        >['request']['abi'],
        'setAllowList',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'setAllowList'
      }
    : UseContractWriteConfig<typeof dystopunksVxABI, 'setAllowList', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setAllowList'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksVxABI, 'setAllowList', TMode>({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'setAllowList',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"setApprovalForAll"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxSetApprovalForAll<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksVxAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksVxABI,
          'setApprovalForAll'
        >['request']['abi'],
        'setApprovalForAll',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'setApprovalForAll'
      }
    : UseContractWriteConfig<
        typeof dystopunksVxABI,
        'setApprovalForAll',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setApprovalForAll'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksVxABI, 'setApprovalForAll', TMode>({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'setApprovalForAll',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"setBaseURI"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxSetBaseUri<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksVxAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksVxABI,
          'setBaseURI'
        >['request']['abi'],
        'setBaseURI',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setBaseURI' }
    : UseContractWriteConfig<typeof dystopunksVxABI, 'setBaseURI', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setBaseURI'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksVxABI, 'setBaseURI', TMode>({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'setBaseURI',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"setClaimMint"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxSetClaimMint<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksVxAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksVxABI,
          'setClaimMint'
        >['request']['abi'],
        'setClaimMint',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'setClaimMint'
      }
    : UseContractWriteConfig<typeof dystopunksVxABI, 'setClaimMint', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setClaimMint'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksVxABI, 'setClaimMint', TMode>({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'setClaimMint',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"setFreeList"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxSetFreeList<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksVxAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksVxABI,
          'setFreeList'
        >['request']['abi'],
        'setFreeList',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'setFreeList'
      }
    : UseContractWriteConfig<typeof dystopunksVxABI, 'setFreeList', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setFreeList'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksVxABI, 'setFreeList', TMode>({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'setFreeList',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"setWhiteList"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxSetWhiteList<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksVxAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksVxABI,
          'setWhiteList'
        >['request']['abi'],
        'setWhiteList',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'setWhiteList'
      }
    : UseContractWriteConfig<typeof dystopunksVxABI, 'setWhiteList', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setWhiteList'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksVxABI, 'setWhiteList', TMode>({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'setWhiteList',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"startPrivateSale"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxStartPrivateSale<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksVxAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksVxABI,
          'startPrivateSale'
        >['request']['abi'],
        'startPrivateSale',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'startPrivateSale'
      }
    : UseContractWriteConfig<
        typeof dystopunksVxABI,
        'startPrivateSale',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'startPrivateSale'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksVxABI, 'startPrivateSale', TMode>({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'startPrivateSale',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"startSale"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxStartSale<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksVxAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksVxABI,
          'startSale'
        >['request']['abi'],
        'startSale',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'startSale' }
    : UseContractWriteConfig<typeof dystopunksVxABI, 'startSale', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'startSale'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksVxABI, 'startSale', TMode>({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'startSale',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"transferFrom"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxTransferFrom<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksVxAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksVxABI,
          'transferFrom'
        >['request']['abi'],
        'transferFrom',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'transferFrom'
      }
    : UseContractWriteConfig<typeof dystopunksVxABI, 'transferFrom', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'transferFrom'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksVxABI, 'transferFrom', TMode>({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'transferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"transferOwnership"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxTransferOwnership<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksVxAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksVxABI,
          'transferOwnership'
        >['request']['abi'],
        'transferOwnership',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'transferOwnership'
      }
    : UseContractWriteConfig<
        typeof dystopunksVxABI,
        'transferOwnership',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'transferOwnership'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksVxABI, 'transferOwnership', TMode>({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'transferOwnership',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"withdrawAll"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxWithdrawAll<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof dystopunksVxAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof dystopunksVxABI,
          'withdrawAll'
        >['request']['abi'],
        'withdrawAll',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'withdrawAll'
      }
    : UseContractWriteConfig<typeof dystopunksVxABI, 'withdrawAll', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'withdrawAll'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof dystopunksVxABI, 'withdrawAll', TMode>({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'withdrawAll',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function usePrepareDystopunksVxWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksVxABI, TFunctionName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksVxABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"DystosClaim"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function usePrepareDystopunksVxDystosClaim(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'DystosClaim'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'DystosClaim',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'DystosClaim'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"approve"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function usePrepareDystopunksVxApprove(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'approve'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'approve'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"mintAllowList"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function usePrepareDystopunksVxMintAllowList(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'mintAllowList'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'mintAllowList',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'mintAllowList'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"mintClaimList"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function usePrepareDystopunksVxMintClaimList(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'mintClaimList'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'mintClaimList',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'mintClaimList'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"mintWhiteList"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function usePrepareDystopunksVxMintWhiteList(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'mintWhiteList'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'mintWhiteList',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'mintWhiteList'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"mintfreeList"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function usePrepareDystopunksVxMintfreeList(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'mintfreeList'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'mintfreeList',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'mintfreeList'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"pausePrivateSale"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function usePrepareDystopunksVxPausePrivateSale(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'pausePrivateSale'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'pausePrivateSale',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof dystopunksVxABI,
    'pausePrivateSale'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"pauseSale"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function usePrepareDystopunksVxPauseSale(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'pauseSale'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'pauseSale',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'pauseSale'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"publicMint"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function usePrepareDystopunksVxPublicMint(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'publicMint'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'publicMint',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'publicMint'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"renounceOwnership"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function usePrepareDystopunksVxRenounceOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'renounceOwnership'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'renounceOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof dystopunksVxABI,
    'renounceOwnership'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"reserveAirdrop"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function usePrepareDystopunksVxReserveAirdrop(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'reserveAirdrop'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'reserveAirdrop',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'reserveAirdrop'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"safeTransferFrom"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function usePrepareDystopunksVxSafeTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'safeTransferFrom'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'safeTransferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof dystopunksVxABI,
    'safeTransferFrom'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"setAllowList"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function usePrepareDystopunksVxSetAllowList(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'setAllowList'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'setAllowList',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'setAllowList'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"setApprovalForAll"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function usePrepareDystopunksVxSetApprovalForAll(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'setApprovalForAll'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'setApprovalForAll',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof dystopunksVxABI,
    'setApprovalForAll'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"setBaseURI"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function usePrepareDystopunksVxSetBaseUri(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'setBaseURI'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'setBaseURI',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'setBaseURI'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"setClaimMint"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function usePrepareDystopunksVxSetClaimMint(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'setClaimMint'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'setClaimMint',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'setClaimMint'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"setFreeList"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function usePrepareDystopunksVxSetFreeList(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'setFreeList'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'setFreeList',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'setFreeList'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"setWhiteList"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function usePrepareDystopunksVxSetWhiteList(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'setWhiteList'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'setWhiteList',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'setWhiteList'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"startPrivateSale"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function usePrepareDystopunksVxStartPrivateSale(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'startPrivateSale'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'startPrivateSale',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof dystopunksVxABI,
    'startPrivateSale'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"startSale"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function usePrepareDystopunksVxStartSale(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'startSale'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'startSale',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'startSale'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"transferFrom"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function usePrepareDystopunksVxTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'transferFrom'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'transferFrom'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"transferOwnership"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function usePrepareDystopunksVxTransferOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'transferOwnership'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'transferOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof dystopunksVxABI,
    'transferOwnership'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link dystopunksVxABI}__ and `functionName` set to `"withdrawAll"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function usePrepareDystopunksVxWithdrawAll(
  config: Omit<
    UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'withdrawAll'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    functionName: 'withdrawAll',
    ...config,
  } as UsePrepareContractWriteConfig<typeof dystopunksVxABI, 'withdrawAll'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link dystopunksVxABI}__.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof dystopunksVxABI, TEventName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractEvent({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    ...config,
  } as UseContractEventConfig<typeof dystopunksVxABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link dystopunksVxABI}__ and `eventName` set to `"Approval"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxApprovalEvent(
  config: Omit<
    UseContractEventConfig<typeof dystopunksVxABI, 'Approval'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractEvent({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    eventName: 'Approval',
    ...config,
  } as UseContractEventConfig<typeof dystopunksVxABI, 'Approval'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link dystopunksVxABI}__ and `eventName` set to `"ApprovalForAll"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxApprovalForAllEvent(
  config: Omit<
    UseContractEventConfig<typeof dystopunksVxABI, 'ApprovalForAll'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractEvent({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    eventName: 'ApprovalForAll',
    ...config,
  } as UseContractEventConfig<typeof dystopunksVxABI, 'ApprovalForAll'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link dystopunksVxABI}__ and `eventName` set to `"OwnershipTransferred"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxOwnershipTransferredEvent(
  config: Omit<
    UseContractEventConfig<typeof dystopunksVxABI, 'OwnershipTransferred'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractEvent({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    eventName: 'OwnershipTransferred',
    ...config,
  } as UseContractEventConfig<typeof dystopunksVxABI, 'OwnershipTransferred'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link dystopunksVxABI}__ and `eventName` set to `"Transfer"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xf91523bc0ffa151abd971f1b11d2567d4167db3e)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xCeC7b9f4A3431AA68c922390Cc3436896E0F7797)
 */
export function useDystopunksVxTransferEvent(
  config: Omit<
    UseContractEventConfig<typeof dystopunksVxABI, 'Transfer'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof dystopunksVxAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractEvent({
    abi: dystopunksVxABI,
    address: dystopunksVxAddress[chainId as keyof typeof dystopunksVxAddress],
    eventName: 'Transfer',
    ...config,
  } as UseContractEventConfig<typeof dystopunksVxABI, 'Transfer'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wagmiMintExampleABI}__.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 */
export function useWagmiMintExampleRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof wagmiMintExampleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<
      typeof wagmiMintExampleABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'address'
  > & { chainId?: keyof typeof wagmiMintExampleAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: wagmiMintExampleABI,
    address:
      wagmiMintExampleAddress[chainId as keyof typeof wagmiMintExampleAddress],
    ...config,
  } as UseContractReadConfig<
    typeof wagmiMintExampleABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wagmiMintExampleABI}__ and `functionName` set to `"balanceOf"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 */
export function useWagmiMintExampleBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof wagmiMintExampleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<
      typeof wagmiMintExampleABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof wagmiMintExampleAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: wagmiMintExampleABI,
    address:
      wagmiMintExampleAddress[chainId as keyof typeof wagmiMintExampleAddress],
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<
    typeof wagmiMintExampleABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wagmiMintExampleABI}__ and `functionName` set to `"getApproved"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 */
export function useWagmiMintExampleGetApproved<
  TFunctionName extends 'getApproved',
  TSelectData = ReadContractResult<typeof wagmiMintExampleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<
      typeof wagmiMintExampleABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof wagmiMintExampleAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: wagmiMintExampleABI,
    address:
      wagmiMintExampleAddress[chainId as keyof typeof wagmiMintExampleAddress],
    functionName: 'getApproved',
    ...config,
  } as UseContractReadConfig<
    typeof wagmiMintExampleABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wagmiMintExampleABI}__ and `functionName` set to `"isApprovedForAll"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 */
export function useWagmiMintExampleIsApprovedForAll<
  TFunctionName extends 'isApprovedForAll',
  TSelectData = ReadContractResult<typeof wagmiMintExampleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<
      typeof wagmiMintExampleABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof wagmiMintExampleAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: wagmiMintExampleABI,
    address:
      wagmiMintExampleAddress[chainId as keyof typeof wagmiMintExampleAddress],
    functionName: 'isApprovedForAll',
    ...config,
  } as UseContractReadConfig<
    typeof wagmiMintExampleABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wagmiMintExampleABI}__ and `functionName` set to `"name"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 */
export function useWagmiMintExampleName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof wagmiMintExampleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<
      typeof wagmiMintExampleABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof wagmiMintExampleAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: wagmiMintExampleABI,
    address:
      wagmiMintExampleAddress[chainId as keyof typeof wagmiMintExampleAddress],
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<
    typeof wagmiMintExampleABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wagmiMintExampleABI}__ and `functionName` set to `"ownerOf"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 */
export function useWagmiMintExampleOwnerOf<
  TFunctionName extends 'ownerOf',
  TSelectData = ReadContractResult<typeof wagmiMintExampleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<
      typeof wagmiMintExampleABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof wagmiMintExampleAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: wagmiMintExampleABI,
    address:
      wagmiMintExampleAddress[chainId as keyof typeof wagmiMintExampleAddress],
    functionName: 'ownerOf',
    ...config,
  } as UseContractReadConfig<
    typeof wagmiMintExampleABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wagmiMintExampleABI}__ and `functionName` set to `"supportsInterface"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 */
export function useWagmiMintExampleSupportsInterface<
  TFunctionName extends 'supportsInterface',
  TSelectData = ReadContractResult<typeof wagmiMintExampleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<
      typeof wagmiMintExampleABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof wagmiMintExampleAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: wagmiMintExampleABI,
    address:
      wagmiMintExampleAddress[chainId as keyof typeof wagmiMintExampleAddress],
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<
    typeof wagmiMintExampleABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wagmiMintExampleABI}__ and `functionName` set to `"symbol"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 */
export function useWagmiMintExampleSymbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<typeof wagmiMintExampleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<
      typeof wagmiMintExampleABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof wagmiMintExampleAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: wagmiMintExampleABI,
    address:
      wagmiMintExampleAddress[chainId as keyof typeof wagmiMintExampleAddress],
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<
    typeof wagmiMintExampleABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wagmiMintExampleABI}__ and `functionName` set to `"tokenURI"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 */
export function useWagmiMintExampleTokenUri<
  TFunctionName extends 'tokenURI',
  TSelectData = ReadContractResult<typeof wagmiMintExampleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<
      typeof wagmiMintExampleABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof wagmiMintExampleAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: wagmiMintExampleABI,
    address:
      wagmiMintExampleAddress[chainId as keyof typeof wagmiMintExampleAddress],
    functionName: 'tokenURI',
    ...config,
  } as UseContractReadConfig<
    typeof wagmiMintExampleABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link wagmiMintExampleABI}__ and `functionName` set to `"totalSupply"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 */
export function useWagmiMintExampleTotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof wagmiMintExampleABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<
      typeof wagmiMintExampleABI,
      TFunctionName,
      TSelectData
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof wagmiMintExampleAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: wagmiMintExampleABI,
    address:
      wagmiMintExampleAddress[chainId as keyof typeof wagmiMintExampleAddress],
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<
    typeof wagmiMintExampleABI,
    TFunctionName,
    TSelectData
  >)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link wagmiMintExampleABI}__.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 */
export function useWagmiMintExampleWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof wagmiMintExampleAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof wagmiMintExampleABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<
        typeof wagmiMintExampleABI,
        TFunctionName,
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof wagmiMintExampleABI, TFunctionName, TMode>({
    abi: wagmiMintExampleABI,
    address:
      wagmiMintExampleAddress[chainId as keyof typeof wagmiMintExampleAddress],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link wagmiMintExampleABI}__ and `functionName` set to `"approve"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 */
export function useWagmiMintExampleApprove<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof wagmiMintExampleAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof wagmiMintExampleABI,
          'approve'
        >['request']['abi'],
        'approve',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'approve' }
    : UseContractWriteConfig<typeof wagmiMintExampleABI, 'approve', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'approve'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof wagmiMintExampleABI, 'approve', TMode>({
    abi: wagmiMintExampleABI,
    address:
      wagmiMintExampleAddress[chainId as keyof typeof wagmiMintExampleAddress],
    functionName: 'approve',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link wagmiMintExampleABI}__ and `functionName` set to `"mint"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 */
export function useWagmiMintExampleMint<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof wagmiMintExampleAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof wagmiMintExampleABI,
          'mint'
        >['request']['abi'],
        'mint',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'mint' }
    : UseContractWriteConfig<typeof wagmiMintExampleABI, 'mint', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'mint'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof wagmiMintExampleABI, 'mint', TMode>({
    abi: wagmiMintExampleABI,
    address:
      wagmiMintExampleAddress[chainId as keyof typeof wagmiMintExampleAddress],
    functionName: 'mint',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link wagmiMintExampleABI}__ and `functionName` set to `"safeTransferFrom"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 */
export function useWagmiMintExampleSafeTransferFrom<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof wagmiMintExampleAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof wagmiMintExampleABI,
          'safeTransferFrom'
        >['request']['abi'],
        'safeTransferFrom',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'safeTransferFrom'
      }
    : UseContractWriteConfig<
        typeof wagmiMintExampleABI,
        'safeTransferFrom',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'safeTransferFrom'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<
    typeof wagmiMintExampleABI,
    'safeTransferFrom',
    TMode
  >({
    abi: wagmiMintExampleABI,
    address:
      wagmiMintExampleAddress[chainId as keyof typeof wagmiMintExampleAddress],
    functionName: 'safeTransferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link wagmiMintExampleABI}__ and `functionName` set to `"setApprovalForAll"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 */
export function useWagmiMintExampleSetApprovalForAll<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof wagmiMintExampleAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof wagmiMintExampleABI,
          'setApprovalForAll'
        >['request']['abi'],
        'setApprovalForAll',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'setApprovalForAll'
      }
    : UseContractWriteConfig<
        typeof wagmiMintExampleABI,
        'setApprovalForAll',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setApprovalForAll'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<
    typeof wagmiMintExampleABI,
    'setApprovalForAll',
    TMode
  >({
    abi: wagmiMintExampleABI,
    address:
      wagmiMintExampleAddress[chainId as keyof typeof wagmiMintExampleAddress],
    functionName: 'setApprovalForAll',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link wagmiMintExampleABI}__ and `functionName` set to `"transferFrom"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 */
export function useWagmiMintExampleTransferFrom<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof wagmiMintExampleAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof wagmiMintExampleABI,
          'transferFrom'
        >['request']['abi'],
        'transferFrom',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'transferFrom'
      }
    : UseContractWriteConfig<
        typeof wagmiMintExampleABI,
        'transferFrom',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'transferFrom'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof wagmiMintExampleABI, 'transferFrom', TMode>({
    abi: wagmiMintExampleABI,
    address:
      wagmiMintExampleAddress[chainId as keyof typeof wagmiMintExampleAddress],
    functionName: 'transferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link wagmiMintExampleABI}__.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 */
export function usePrepareWagmiMintExampleWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof wagmiMintExampleABI, TFunctionName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof wagmiMintExampleAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: wagmiMintExampleABI,
    address:
      wagmiMintExampleAddress[chainId as keyof typeof wagmiMintExampleAddress],
    ...config,
  } as UsePrepareContractWriteConfig<typeof wagmiMintExampleABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link wagmiMintExampleABI}__ and `functionName` set to `"approve"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 */
export function usePrepareWagmiMintExampleApprove(
  config: Omit<
    UsePrepareContractWriteConfig<typeof wagmiMintExampleABI, 'approve'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof wagmiMintExampleAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: wagmiMintExampleABI,
    address:
      wagmiMintExampleAddress[chainId as keyof typeof wagmiMintExampleAddress],
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof wagmiMintExampleABI, 'approve'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link wagmiMintExampleABI}__ and `functionName` set to `"mint"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 */
export function usePrepareWagmiMintExampleMint(
  config: Omit<
    UsePrepareContractWriteConfig<typeof wagmiMintExampleABI, 'mint'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof wagmiMintExampleAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: wagmiMintExampleABI,
    address:
      wagmiMintExampleAddress[chainId as keyof typeof wagmiMintExampleAddress],
    functionName: 'mint',
    ...config,
  } as UsePrepareContractWriteConfig<typeof wagmiMintExampleABI, 'mint'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link wagmiMintExampleABI}__ and `functionName` set to `"safeTransferFrom"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 */
export function usePrepareWagmiMintExampleSafeTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof wagmiMintExampleABI,
      'safeTransferFrom'
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof wagmiMintExampleAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: wagmiMintExampleABI,
    address:
      wagmiMintExampleAddress[chainId as keyof typeof wagmiMintExampleAddress],
    functionName: 'safeTransferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof wagmiMintExampleABI,
    'safeTransferFrom'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link wagmiMintExampleABI}__ and `functionName` set to `"setApprovalForAll"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 */
export function usePrepareWagmiMintExampleSetApprovalForAll(
  config: Omit<
    UsePrepareContractWriteConfig<
      typeof wagmiMintExampleABI,
      'setApprovalForAll'
    >,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof wagmiMintExampleAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: wagmiMintExampleABI,
    address:
      wagmiMintExampleAddress[chainId as keyof typeof wagmiMintExampleAddress],
    functionName: 'setApprovalForAll',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof wagmiMintExampleABI,
    'setApprovalForAll'
  >)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link wagmiMintExampleABI}__ and `functionName` set to `"transferFrom"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 */
export function usePrepareWagmiMintExampleTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof wagmiMintExampleABI, 'transferFrom'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof wagmiMintExampleAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: wagmiMintExampleABI,
    address:
      wagmiMintExampleAddress[chainId as keyof typeof wagmiMintExampleAddress],
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<
    typeof wagmiMintExampleABI,
    'transferFrom'
  >)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link wagmiMintExampleABI}__.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 */
export function useWagmiMintExampleEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof wagmiMintExampleABI, TEventName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof wagmiMintExampleAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractEvent({
    abi: wagmiMintExampleABI,
    address:
      wagmiMintExampleAddress[chainId as keyof typeof wagmiMintExampleAddress],
    ...config,
  } as UseContractEventConfig<typeof wagmiMintExampleABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link wagmiMintExampleABI}__ and `eventName` set to `"Approval"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 */
export function useWagmiMintExampleApprovalEvent(
  config: Omit<
    UseContractEventConfig<typeof wagmiMintExampleABI, 'Approval'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof wagmiMintExampleAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractEvent({
    abi: wagmiMintExampleABI,
    address:
      wagmiMintExampleAddress[chainId as keyof typeof wagmiMintExampleAddress],
    eventName: 'Approval',
    ...config,
  } as UseContractEventConfig<typeof wagmiMintExampleABI, 'Approval'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link wagmiMintExampleABI}__ and `eventName` set to `"ApprovalForAll"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 */
export function useWagmiMintExampleApprovalForAllEvent(
  config: Omit<
    UseContractEventConfig<typeof wagmiMintExampleABI, 'ApprovalForAll'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof wagmiMintExampleAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractEvent({
    abi: wagmiMintExampleABI,
    address:
      wagmiMintExampleAddress[chainId as keyof typeof wagmiMintExampleAddress],
    eventName: 'ApprovalForAll',
    ...config,
  } as UseContractEventConfig<typeof wagmiMintExampleABI, 'ApprovalForAll'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link wagmiMintExampleABI}__ and `eventName` set to `"Transfer"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 * - [__View Contract on Goerli Etherscan__](https://goerli.etherscan.io/address/0xfba3912ca04dd458c843e2ee08967fc04f3579c2)
 */
export function useWagmiMintExampleTransferEvent(
  config: Omit<
    UseContractEventConfig<typeof wagmiMintExampleABI, 'Transfer'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof wagmiMintExampleAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractEvent({
    abi: wagmiMintExampleABI,
    address:
      wagmiMintExampleAddress[chainId as keyof typeof wagmiMintExampleAddress],
    eventName: 'Transfer',
    ...config,
  } as UseContractEventConfig<typeof wagmiMintExampleABI, 'Transfer'>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"availableDystoToClaim"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onAvailableDystoToClaim<
  TFunctionName extends 'availableDystoToClaim',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'availableDystoToClaim',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"availableKeyClaim"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onAvailableKeyClaim<
  TFunctionName extends 'availableKeyClaim',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'availableKeyClaim',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"balanceOf"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"checkDystoToClaim"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onCheckDystoToClaim<
  TFunctionName extends 'checkDystoToClaim',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'checkDystoToClaim',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"checkKeyClaim"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onCheckKeyClaim<
  TFunctionName extends 'checkKeyClaim',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'checkKeyClaim',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"claimed"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onClaimed<
  TFunctionName extends 'claimed',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'claimed',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"claimedDysto"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onClaimedDysto<
  TFunctionName extends 'claimedDysto',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'claimedDysto',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"claimedKey"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onClaimedKey<
  TFunctionName extends 'claimedKey',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'claimedKey',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"claimRoot"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onClaimRoot<
  TFunctionName extends 'claimRoot',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'claimRoot',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"collectionSize"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onCollectionSize<
  TFunctionName extends 'collectionSize',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'collectionSize',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"DystoAddress"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onDystoAddress<
  TFunctionName extends 'DystoAddress',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'DystoAddress',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"EncodeAddress"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onEncodeAddress<
  TFunctionName extends 'EncodeAddress',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'EncodeAddress',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"explicitOwnershipOf"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onExplicitOwnershipOf<
  TFunctionName extends 'explicitOwnershipOf',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'explicitOwnershipOf',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"explicitOwnershipsOf"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onExplicitOwnershipsOf<
  TFunctionName extends 'explicitOwnershipsOf',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'explicitOwnershipsOf',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"getApproved"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onGetApproved<
  TFunctionName extends 'getApproved',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'getApproved',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"isApprovedForAll"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onIsApprovedForAll<
  TFunctionName extends 'isApprovedForAll',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'isApprovedForAll',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"name"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"numberMinted"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onNumberMinted<
  TFunctionName extends 'numberMinted',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'numberMinted',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"owner"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onOwner<
  TFunctionName extends 'owner',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'owner',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"ownerOf"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onOwnerOf<
  TFunctionName extends 'ownerOf',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'ownerOf',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"publicPrice"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onPublicPrice<
  TFunctionName extends 'publicPrice',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'publicPrice',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"reserved"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onReserved<
  TFunctionName extends 'reserved',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'reserved',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"saleConfig"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onSaleConfig<
  TFunctionName extends 'saleConfig',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'saleConfig',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"stoped"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onStoped<
  TFunctionName extends 'stoped',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'stoped',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"supportsInterface"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onSupportsInterface<
  TFunctionName extends 'supportsInterface',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"symbol"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onSymbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"tokensOfOwner"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onTokensOfOwner<
  TFunctionName extends 'tokensOfOwner',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'tokensOfOwner',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"tokensOfOwnerIn"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onTokensOfOwnerIn<
  TFunctionName extends 'tokensOfOwnerIn',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'tokensOfOwnerIn',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"tokenURI"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onTokenUri<
  TFunctionName extends 'tokenURI',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'tokenURI',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"totalSupply"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onTotalSupply<
  TFunctionName extends 'totalSupply',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'totalSupply',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"VXAddress"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onVxAddress<
  TFunctionName extends 'VXAddress',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'VXAddress',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"whitelistPrice"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onWhitelistPrice<
  TFunctionName extends 'whitelistPrice',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'whitelistPrice',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"whitelistRoot"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onWhitelistRoot<
  TFunctionName extends 'whitelistRoot',
  TSelectData = ReadContractResult<typeof leg1onABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractRead({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'whitelistRoot',
    ...config,
  } as UseContractReadConfig<typeof leg1onABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link leg1onABI}__.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof leg1onAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<typeof leg1onABI, string>['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof leg1onABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof leg1onABI, TFunctionName, TMode>({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"approve"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onApprove<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof leg1onAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof leg1onABI,
          'approve'
        >['request']['abi'],
        'approve',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'approve' }
    : UseContractWriteConfig<typeof leg1onABI, 'approve', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'approve'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof leg1onABI, 'approve', TMode>({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'approve',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"dystoClaim"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onDystoClaim<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof leg1onAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof leg1onABI,
          'dystoClaim'
        >['request']['abi'],
        'dystoClaim',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'dystoClaim' }
    : UseContractWriteConfig<typeof leg1onABI, 'dystoClaim', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'dystoClaim'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof leg1onABI, 'dystoClaim', TMode>({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'dystoClaim',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"freeClaim"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onFreeClaim<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof leg1onAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof leg1onABI,
          'freeClaim'
        >['request']['abi'],
        'freeClaim',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'freeClaim' }
    : UseContractWriteConfig<typeof leg1onABI, 'freeClaim', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'freeClaim'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof leg1onABI, 'freeClaim', TMode>({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'freeClaim',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"keyClaim"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onKeyClaim<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof leg1onAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof leg1onABI,
          'keyClaim'
        >['request']['abi'],
        'keyClaim',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'keyClaim' }
    : UseContractWriteConfig<typeof leg1onABI, 'keyClaim', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'keyClaim'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof leg1onABI, 'keyClaim', TMode>({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'keyClaim',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"mintVXList"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onMintVxList<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof leg1onAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof leg1onABI,
          'mintVXList'
        >['request']['abi'],
        'mintVXList',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'mintVXList' }
    : UseContractWriteConfig<typeof leg1onABI, 'mintVXList', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'mintVXList'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof leg1onABI, 'mintVXList', TMode>({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'mintVXList',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"publicMint"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onPublicMint<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof leg1onAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof leg1onABI,
          'publicMint'
        >['request']['abi'],
        'publicMint',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'publicMint' }
    : UseContractWriteConfig<typeof leg1onABI, 'publicMint', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'publicMint'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof leg1onABI, 'publicMint', TMode>({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'publicMint',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"renounceOwnership"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onRenounceOwnership<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof leg1onAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof leg1onABI,
          'renounceOwnership'
        >['request']['abi'],
        'renounceOwnership',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'renounceOwnership'
      }
    : UseContractWriteConfig<typeof leg1onABI, 'renounceOwnership', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'renounceOwnership'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof leg1onABI, 'renounceOwnership', TMode>({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'renounceOwnership',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"reserveAirdrop"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onReserveAirdrop<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof leg1onAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof leg1onABI,
          'reserveAirdrop'
        >['request']['abi'],
        'reserveAirdrop',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'reserveAirdrop'
      }
    : UseContractWriteConfig<typeof leg1onABI, 'reserveAirdrop', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'reserveAirdrop'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof leg1onABI, 'reserveAirdrop', TMode>({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'reserveAirdrop',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"safeTransferFrom"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onSafeTransferFrom<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof leg1onAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof leg1onABI,
          'safeTransferFrom'
        >['request']['abi'],
        'safeTransferFrom',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'safeTransferFrom'
      }
    : UseContractWriteConfig<typeof leg1onABI, 'safeTransferFrom', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'safeTransferFrom'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof leg1onABI, 'safeTransferFrom', TMode>({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'safeTransferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"setApprovalForAll"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onSetApprovalForAll<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof leg1onAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof leg1onABI,
          'setApprovalForAll'
        >['request']['abi'],
        'setApprovalForAll',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'setApprovalForAll'
      }
    : UseContractWriteConfig<typeof leg1onABI, 'setApprovalForAll', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setApprovalForAll'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof leg1onABI, 'setApprovalForAll', TMode>({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'setApprovalForAll',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"setBaseURI"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onSetBaseUri<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof leg1onAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof leg1onABI,
          'setBaseURI'
        >['request']['abi'],
        'setBaseURI',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'setBaseURI' }
    : UseContractWriteConfig<typeof leg1onABI, 'setBaseURI', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setBaseURI'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof leg1onABI, 'setBaseURI', TMode>({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'setBaseURI',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"setClaimRoot"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onSetClaimRoot<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof leg1onAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof leg1onABI,
          'setClaimRoot'
        >['request']['abi'],
        'setClaimRoot',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'setClaimRoot'
      }
    : UseContractWriteConfig<typeof leg1onABI, 'setClaimRoot', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setClaimRoot'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof leg1onABI, 'setClaimRoot', TMode>({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'setClaimRoot',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"setCollectionSize"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onSetCollectionSize<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof leg1onAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof leg1onABI,
          'setCollectionSize'
        >['request']['abi'],
        'setCollectionSize',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'setCollectionSize'
      }
    : UseContractWriteConfig<typeof leg1onABI, 'setCollectionSize', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setCollectionSize'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof leg1onABI, 'setCollectionSize', TMode>({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'setCollectionSize',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"setupSaleInfo"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onSetupSaleInfo<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof leg1onAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof leg1onABI,
          'setupSaleInfo'
        >['request']['abi'],
        'setupSaleInfo',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'setupSaleInfo'
      }
    : UseContractWriteConfig<typeof leg1onABI, 'setupSaleInfo', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setupSaleInfo'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof leg1onABI, 'setupSaleInfo', TMode>({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'setupSaleInfo',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"setWhitelistRoot"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onSetWhitelistRoot<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof leg1onAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof leg1onABI,
          'setWhitelistRoot'
        >['request']['abi'],
        'setWhitelistRoot',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'setWhitelistRoot'
      }
    : UseContractWriteConfig<typeof leg1onABI, 'setWhitelistRoot', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setWhitelistRoot'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof leg1onABI, 'setWhitelistRoot', TMode>({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'setWhitelistRoot',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"stopSale"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onStopSale<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof leg1onAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof leg1onABI,
          'stopSale'
        >['request']['abi'],
        'stopSale',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'stopSale' }
    : UseContractWriteConfig<typeof leg1onABI, 'stopSale', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'stopSale'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof leg1onABI, 'stopSale', TMode>({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'stopSale',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"transferFrom"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onTransferFrom<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof leg1onAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof leg1onABI,
          'transferFrom'
        >['request']['abi'],
        'transferFrom',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'transferFrom'
      }
    : UseContractWriteConfig<typeof leg1onABI, 'transferFrom', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'transferFrom'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof leg1onABI, 'transferFrom', TMode>({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'transferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"transferOwnership"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onTransferOwnership<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof leg1onAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof leg1onABI,
          'transferOwnership'
        >['request']['abi'],
        'transferOwnership',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'transferOwnership'
      }
    : UseContractWriteConfig<typeof leg1onABI, 'transferOwnership', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'transferOwnership'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof leg1onABI, 'transferOwnership', TMode>({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'transferOwnership',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"whitelistMint"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onWhitelistMint<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof leg1onAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof leg1onABI,
          'whitelistMint'
        >['request']['abi'],
        'whitelistMint',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'whitelistMint'
      }
    : UseContractWriteConfig<typeof leg1onABI, 'whitelistMint', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'whitelistMint'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof leg1onABI, 'whitelistMint', TMode>({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'whitelistMint',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"withdraw"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onWithdraw<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof leg1onAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof leg1onABI,
          'withdraw'
        >['request']['abi'],
        'withdraw',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'withdraw' }
    : UseContractWriteConfig<typeof leg1onABI, 'withdraw', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'withdraw'
      } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractWrite<typeof leg1onABI, 'withdraw', TMode>({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'withdraw',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link leg1onABI}__.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function usePrepareLeg1onWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof leg1onABI, TFunctionName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    ...config,
  } as UsePrepareContractWriteConfig<typeof leg1onABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"approve"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function usePrepareLeg1onApprove(
  config: Omit<
    UsePrepareContractWriteConfig<typeof leg1onABI, 'approve'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof leg1onABI, 'approve'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"dystoClaim"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function usePrepareLeg1onDystoClaim(
  config: Omit<
    UsePrepareContractWriteConfig<typeof leg1onABI, 'dystoClaim'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'dystoClaim',
    ...config,
  } as UsePrepareContractWriteConfig<typeof leg1onABI, 'dystoClaim'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"freeClaim"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function usePrepareLeg1onFreeClaim(
  config: Omit<
    UsePrepareContractWriteConfig<typeof leg1onABI, 'freeClaim'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'freeClaim',
    ...config,
  } as UsePrepareContractWriteConfig<typeof leg1onABI, 'freeClaim'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"keyClaim"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function usePrepareLeg1onKeyClaim(
  config: Omit<
    UsePrepareContractWriteConfig<typeof leg1onABI, 'keyClaim'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'keyClaim',
    ...config,
  } as UsePrepareContractWriteConfig<typeof leg1onABI, 'keyClaim'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"mintVXList"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function usePrepareLeg1onMintVxList(
  config: Omit<
    UsePrepareContractWriteConfig<typeof leg1onABI, 'mintVXList'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'mintVXList',
    ...config,
  } as UsePrepareContractWriteConfig<typeof leg1onABI, 'mintVXList'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"publicMint"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function usePrepareLeg1onPublicMint(
  config: Omit<
    UsePrepareContractWriteConfig<typeof leg1onABI, 'publicMint'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'publicMint',
    ...config,
  } as UsePrepareContractWriteConfig<typeof leg1onABI, 'publicMint'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"renounceOwnership"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function usePrepareLeg1onRenounceOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof leg1onABI, 'renounceOwnership'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'renounceOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof leg1onABI, 'renounceOwnership'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"reserveAirdrop"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function usePrepareLeg1onReserveAirdrop(
  config: Omit<
    UsePrepareContractWriteConfig<typeof leg1onABI, 'reserveAirdrop'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'reserveAirdrop',
    ...config,
  } as UsePrepareContractWriteConfig<typeof leg1onABI, 'reserveAirdrop'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"safeTransferFrom"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function usePrepareLeg1onSafeTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof leg1onABI, 'safeTransferFrom'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'safeTransferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof leg1onABI, 'safeTransferFrom'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"setApprovalForAll"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function usePrepareLeg1onSetApprovalForAll(
  config: Omit<
    UsePrepareContractWriteConfig<typeof leg1onABI, 'setApprovalForAll'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'setApprovalForAll',
    ...config,
  } as UsePrepareContractWriteConfig<typeof leg1onABI, 'setApprovalForAll'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"setBaseURI"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function usePrepareLeg1onSetBaseUri(
  config: Omit<
    UsePrepareContractWriteConfig<typeof leg1onABI, 'setBaseURI'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'setBaseURI',
    ...config,
  } as UsePrepareContractWriteConfig<typeof leg1onABI, 'setBaseURI'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"setClaimRoot"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function usePrepareLeg1onSetClaimRoot(
  config: Omit<
    UsePrepareContractWriteConfig<typeof leg1onABI, 'setClaimRoot'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'setClaimRoot',
    ...config,
  } as UsePrepareContractWriteConfig<typeof leg1onABI, 'setClaimRoot'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"setCollectionSize"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function usePrepareLeg1onSetCollectionSize(
  config: Omit<
    UsePrepareContractWriteConfig<typeof leg1onABI, 'setCollectionSize'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'setCollectionSize',
    ...config,
  } as UsePrepareContractWriteConfig<typeof leg1onABI, 'setCollectionSize'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"setupSaleInfo"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function usePrepareLeg1onSetupSaleInfo(
  config: Omit<
    UsePrepareContractWriteConfig<typeof leg1onABI, 'setupSaleInfo'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'setupSaleInfo',
    ...config,
  } as UsePrepareContractWriteConfig<typeof leg1onABI, 'setupSaleInfo'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"setWhitelistRoot"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function usePrepareLeg1onSetWhitelistRoot(
  config: Omit<
    UsePrepareContractWriteConfig<typeof leg1onABI, 'setWhitelistRoot'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'setWhitelistRoot',
    ...config,
  } as UsePrepareContractWriteConfig<typeof leg1onABI, 'setWhitelistRoot'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"stopSale"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function usePrepareLeg1onStopSale(
  config: Omit<
    UsePrepareContractWriteConfig<typeof leg1onABI, 'stopSale'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'stopSale',
    ...config,
  } as UsePrepareContractWriteConfig<typeof leg1onABI, 'stopSale'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"transferFrom"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function usePrepareLeg1onTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof leg1onABI, 'transferFrom'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof leg1onABI, 'transferFrom'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"transferOwnership"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function usePrepareLeg1onTransferOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof leg1onABI, 'transferOwnership'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'transferOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof leg1onABI, 'transferOwnership'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"whitelistMint"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function usePrepareLeg1onWhitelistMint(
  config: Omit<
    UsePrepareContractWriteConfig<typeof leg1onABI, 'whitelistMint'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'whitelistMint',
    ...config,
  } as UsePrepareContractWriteConfig<typeof leg1onABI, 'whitelistMint'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link leg1onABI}__ and `functionName` set to `"withdraw"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function usePrepareLeg1onWithdraw(
  config: Omit<
    UsePrepareContractWriteConfig<typeof leg1onABI, 'withdraw'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return usePrepareContractWrite({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    functionName: 'withdraw',
    ...config,
  } as UsePrepareContractWriteConfig<typeof leg1onABI, 'withdraw'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link leg1onABI}__.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof leg1onABI, TEventName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractEvent({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    ...config,
  } as UseContractEventConfig<typeof leg1onABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link leg1onABI}__ and `eventName` set to `"Approval"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onApprovalEvent(
  config: Omit<
    UseContractEventConfig<typeof leg1onABI, 'Approval'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractEvent({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    eventName: 'Approval',
    ...config,
  } as UseContractEventConfig<typeof leg1onABI, 'Approval'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link leg1onABI}__ and `eventName` set to `"ApprovalForAll"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onApprovalForAllEvent(
  config: Omit<
    UseContractEventConfig<typeof leg1onABI, 'ApprovalForAll'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractEvent({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    eventName: 'ApprovalForAll',
    ...config,
  } as UseContractEventConfig<typeof leg1onABI, 'ApprovalForAll'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link leg1onABI}__ and `eventName` set to `"ConsecutiveTransfer"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onConsecutiveTransferEvent(
  config: Omit<
    UseContractEventConfig<typeof leg1onABI, 'ConsecutiveTransfer'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractEvent({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    eventName: 'ConsecutiveTransfer',
    ...config,
  } as UseContractEventConfig<typeof leg1onABI, 'ConsecutiveTransfer'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link leg1onABI}__ and `eventName` set to `"OwnershipTransferred"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onOwnershipTransferredEvent(
  config: Omit<
    UseContractEventConfig<typeof leg1onABI, 'OwnershipTransferred'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractEvent({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    eventName: 'OwnershipTransferred',
    ...config,
  } as UseContractEventConfig<typeof leg1onABI, 'OwnershipTransferred'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link leg1onABI}__ and `eventName` set to `"Transfer"`.
 *
 * - [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0xA2B63e4C1ea4f494F6d63Ac1c5A673c5BcC43b6C)
 * - [__View Contract on Sepolia Etherscan__](https://sepolia.etherscan.io/address/0xD3Bc3dcE8A74aC5fd32B439A714440FC396f6Db7)
 */
export function useLeg1onTransferEvent(
  config: Omit<
    UseContractEventConfig<typeof leg1onABI, 'Transfer'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof leg1onAddress } = {} as any,
) {
  const { chain } = useNetwork()
  const defaultChainId = useChainId()
  const chainId = config.chainId ?? chain?.id ?? defaultChainId
  return useContractEvent({
    abi: leg1onABI,
    address: leg1onAddress[chainId as keyof typeof leg1onAddress],
    eventName: 'Transfer',
    ...config,
  } as UseContractEventConfig<typeof leg1onABI, 'Transfer'>)
}
