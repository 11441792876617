import React, { useEffect, useState } from 'react'
import './styles.css';
import { useDystopunksVxBalanceOf, useLeg1onMintVxList, usePrepareLeg1onMintVxList } from '../../generated.ts';
import { parseEther } from 'viem';
import { useWaitForTransaction } from 'wagmi';

function VxAllowlistMint({ address }) {
  const [ hasDystopunksVx, setHasDystopunksVx ] = useState(false)
  const pricePerMint = '0.05'
  const [mintAmount, setMintAmount] = useState("");
  const [price, setPrice] = useState('')
  const [mintEnabled, setMintEnabled] = useState(false)

 // use opensea api to get dystovx holding
 // contract does not contain wallet fetch function
 const {data: dystopunksVxData } = useDystopunksVxBalanceOf({
    args: [address],
 })

 // console.log("Dystopunks VX: ", dystopunksVxData)

 const handleMintAmountChange = (e) => {
  if (e.target.value >= 1 && e.target.value <= 7) {
    setMintAmount(e.target.value);
    setPrice(String(parseFloat(pricePerMint) * parseInt(e.target.value)))
  } else {
    setMintAmount(1);
    setPrice(String(parseFloat(pricePerMint) * parseInt(1)))
  }
};


const {
  config,
  error: prepareError,
  isError: isPrepareError,
  } = usePrepareLeg1onMintVxList({
      enabled: hasDystopunksVx && mintEnabled,
      args: [mintAmount],
      value: parseEther(price),
})

const { data: mintData, error, isError, isLoading: isMintLoading, write } = useLeg1onMintVxList(config)

const { isLoading, isSuccess } = useWaitForTransaction({
  hash: mintData?.hash,
})

useEffect(() => {
if (dystopunksVxData > 0) {
  setHasDystopunksVx(true)
} else {
  setHasDystopunksVx(false)
}
}, [dystopunksVxData])

if (prepareError || isPrepareError){
  console.log(prepareError || isPrepareError)
}

if (error) {
  console.log(error)
}

useEffect(() => {
  if(parseInt(mintAmount) > 0) {
    setMintEnabled(true)
  } else {
    setMintEnabled(false)
  }
}, [mintAmount])

if (hasDystopunksVx)
  return (<><br />
    <div className="mint py-4 px-4 mint-item">
      <div className="row">
        <div className="col-md-6">
          <h4 className="strong">VX ALLOWLIST MINT</h4>
        </div>
        <div className="col-md-3">
          <h4 className="mint-tag">27 DEC</h4>
        </div>
        <div className="col-md-3">
          <h4 className="mint-tag">0.05 ETH</h4>
        </div>
      </div>
      <p className="mint-text">
        You're in the DystoPunks VX Allowlist! You can mint up to 7 NFTs per trx:
      </p>
      <div className="row">
        <div className="col-md-4 py-1 px-1">
          <input 
            type="number" 
            className="form-control" 
            placeholder="Max. 7" 
            min="1" 
            max="7" 
            value={mintAmount}
            onChange={handleMintAmountChange}
            aria-label="Dysto Claim" 
            aria-describedby="mint-button" 
          />
        </div> 
        <div className="col-md-6">
          <button className="btn btn-secondary" 
          onClick={() => write?.()}
            disabled={isMintLoading || isLoading || isPrepareError}
          >
            {isMintLoading && "AWAITING..."}
            {!isMintLoading && !isLoading && !isSuccess && "MINT"}
            {!isMintLoading && !isLoading && isSuccess && "MINTED!"}
            {isLoading && "MINTING..."}</button>
        </div>
        {isError && <h6>There was an error.</h6>}
      </div>
    </div>
    </>
  );
}

export default VxAllowlistMint