import React, { useEffect, useState } from 'react'
import './styles.css';
import { useLeg1onWhitelistMint, usePrepareLeg1onWhitelistMint } from '../../generated.ts';
import { parseEther } from 'viem';
import { useWaitForTransaction } from 'wagmi';
import whitelistVerifier from '../../helpers/merkleVerifyWhitelist.js';

function AllowlistMint({ address }) {
  const pricePerMint = '0.05'
  const [ hasAllowlist, setHasAllowlist ] = useState(false)
  const [proof, setProof] = useState([])
  const [mintAmount, setMintAmount] = useState('');
  const [price, setPrice] = useState('')
  const [mintEnabled, setMintEnabled] = useState(false)

  const handleMintAmountChange = (e) => {
    if (e.target.value >= 1 && e.target.value <= 7) {
      setMintAmount(e.target.value);
      setPrice(String(parseFloat(pricePerMint) * parseInt(e.target.value)))
    } else {
      setMintAmount(1);
      setPrice(String(parseFloat(pricePerMint) * parseInt(1)))
    }
  };

  const {
    config,
    error: prepareError,
    isError: isPrepareError,
    } = usePrepareLeg1onWhitelistMint({
        enabled: hasAllowlist && mintEnabled,
        args: [proof, mintAmount],
        value: parseEther(price),
    })

  const { data: mintData, error, isError, isLoading: isMintLoading, write } = useLeg1onWhitelistMint(config)

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: mintData?.hash,
})

if (prepareError || isPrepareError){
  console.log(prepareError || isPrepareError)
}

if (error) {
  console.log(error)
}

useEffect(() => {
  const isWhitelisted = whitelistVerifier(address)
  if (isWhitelisted !== "No whitelist found.") {
    setProof(isWhitelisted)
    setHasAllowlist(true)
  } else {
    setHasAllowlist(false)
    setProof([])
  }
}, [address])

useEffect(() => {
  if(parseInt(mintAmount) > 0) {
    setMintEnabled(true)
  } else {
    setMintEnabled(false)
  }
}, [mintAmount])


if (hasAllowlist)
  return (<>
  <br />
    <div className="mint py-4 px-4 mint-item">
      <div className="row">
        <div className="col-md-6">
          <h4 className="strong">ALLOWLIST MINT</h4>
        </div>
        <div className="col-md-3">
          <h4 className="mint-tag">27 DEC</h4>
        </div>
        <div className="col-md-3">
          <h4 className="mint-tag">0.05 ETH</h4>
        </div>
      </div>
      <p className="mint-text">
        You're in the Allowlist! You can mint up to 7 NFTs per trx:
      </p>
      <div className="row">
        <div className="col-md-4 py-1 px-1">
          <input 
            type="number" 
            className="form-control" 
            placeholder="Max. 7" 
            min="1" 
            max="7" 
            value={mintAmount}
            onChange={handleMintAmountChange}
            aria-label="Dysto Claim" 
            aria-describedby="mint-button" 
          />
        </div> 
        <div className="col-md-6">
          <button 
          className="btn btn-secondary" 
          onClick={() => write?.()}
            disabled={isMintLoading || isLoading}
          >
            {isMintLoading && "AWAITING..."}
            {!isMintLoading && !isLoading && !isSuccess && "MINT"}
            {!isMintLoading && !isLoading && isSuccess && "MINTED!"}
            {isLoading && "MINTING..."}
          </button>
        </div>
        {isError && <h6>There was an error.</h6>}
      </div>
    </div>
    </>
  );
}

export default AllowlistMint