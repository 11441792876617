import React from "react";

function LegionCards() {
  return (
    <>
      <div className="weare text-center py-3">
        <div className="container">
          <div className="row section">
            <h1 className="d-none d-xl-block">
              WE ARE LEG1ON! || FOR THE PEOPLE!
            </h1>
            <h1 className="d-xl-none">WE ARE LEG1ON!</h1>
          </div>
        </div>
      </div>

      <div className="cards text-center py-5 px-4 d-none d-xxl-block">
        <div className="row section">
          {["wiz", "dante", "raiden", "webster", "mastix", "snakebyte"].map(
            (card, index) => (
              <div key={index} className="col-sm-2">
                <img
                  src={`img/cards/${card}.jpg`}
                  alt={card.charAt(0).toUpperCase() + card.slice(1)}
                  className="img-fluid card-item"
                />
              </div>
            )
          )}
        </div>
      </div>

      <div className="cards text-center py-5 px-4 d-none d-lg-block d-xxl-none">
        <div className="row section">
          {["wiz", "dante", "raiden", "webster"].map((card, index) => (
            <div key={index} className="col-sm-3">
              <img
                src={`img/cards/${card}.jpg`}
                alt={card.charAt(0).toUpperCase() + card.slice(1)}
                className="img-fluid card-item"
              />
            </div>
          ))}
        </div>
      </div>

      <div className="cards text-center py-5 px-4 d-lg-none">
        <div className="row section">
          {["wiz", "dante", "raiden"].map((card, index) => (
            <div key={index} className="col-sm-4">
              <img
                src={`img/cards/${card}.jpg`}
                alt={card.charAt(0).toUpperCase() + card.slice(1)}
                className="img-fluid card-item"
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default LegionCards;
