import React from "react";

function Capsule() {
  return (
    <div id="Capsule" className="container text-center py-5">
      <div className="row section">
        <div className="col-lg-6">
          <br />
          <br />
          <h2 className="dystopunks">TAKE THE LEG1ON PILL</h2>
          <br />
          <p className="lead">
            LEG1ON is a high-quality cyberpunk-themed collection of ERC-721
            standard NFTs in the Ethereum Blockchain. Collectible avatars with
            unique comic-style artworks that give you access to <a
              href="https://encode.graphics/"
              target="_blank"
              rel="noopener noreferrer"
            >
              ENCODE Graphics
            </a>{" "}
            network and <a
              href="https://dystopunks.net/"
              target="_blank"
              rel="noopener noreferrer"
            >
              DystoPunks
            </a>{" "}
            ecosystem.
            <br />
            <br />
            Characters designed for an immersive dystopian storytelling with
            high-tech and robots. LEG1ON holders will have full access to both
            communities, exclusive airdrops, metaverse experiences, Web3 games
            and more.
            <br />
            <br />
            Take the capsule and see you on the other side!
          </p>
          <br />
          <a
            href="https://blur.io/collection/leg1on"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-lg btn-secondary"
          >
            Trade on Blur
          </a>
          <br />
          <br />
        </div>
        <div className="col-lg-6">
          <br />
          <img
            src="img/content/capsule.png"
            alt="Capsule"
            className="img-fluid card-item mx-auto capsule d-none d-xxl-block"
          />
          <img
            src="img/content/capsule2.png"
            alt="Capsule"
            className="img-fluid card-item mx-auto capsule d-none d-lg-block d-xxl-none"
          />
        </div>
      </div>
    </div>
  );
}

export default Capsule;
