import React from "react";
import { Link } from "react-router-dom";

function WizComics() {
  return (
    <div id="WIZ_Comics" className="comics text-center py-5">
      <div className="container">
        <div className="row section">
          <div className="col-lg-6">
            <img
              src="img/content/wiz.png"
              alt="WIZ"
              className="img-fluid d-lg-none"
            />
            <img
              src="img/content/wiz.png"
              alt="WIZ"
              className="img-fluid wiz d-none d-lg-block"
            />
          </div>
          <div className="col-lg-6 bg-text">
            <br />
            <br />
            <h2 className="dystopunks">WIZ COMICS UNIVERSE</h2>
            <br />
            <p className="lead">
              The series WIZ represents a special sub-project forming an early
              partnership that helped the mutual growth of both projects. The
              special thing about WIZ is that this high-quality comic is
              basically exclusively available digitally and is free to read for
              everyone. It is only reserved for the holders of the{" "}
              <a
                href="https://opensea.io/collection/encode-key"
                target="_blank"
                rel="noopener noreferrer"
              >
                ENCODE Keys
              </a>{" "}
              to claim the comics as NFT and thus get a rare digital art
              collectible and also enjoy a physical copy of each issue.
              <br />
              <br />
              In a dystopian future, which has been shaken by a catastrophe of
              global proportions, we meet the characters Wiz, Raiden and
              Nathaniel, who seek to prevent the reawakening of an artificial
              intelligence called Datamaster. In doing so, they hope to get help
              from Arian, the leader of the Ariasi. Hunted by Dante and his
              henchman Commander G, they must make their way through the ruins
              of Greystone City to avert the danger.
            </p>
            <br />
            <Link to="wiz" className="btn btn-lg btn-primary">
              READ WIZ COMICS
            </Link>
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default WizComics;
