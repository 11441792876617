import React, { useEffect, useState } from 'react'
import './styles.css';
import { useLeg1onCheckDystoToClaim, useLeg1onCheckKeyClaim } from '../../generated.ts';

function CheckClaimEligibility() {
  const [dystoPunkNumber, setDystoPunkNumber] = useState('');
  const [dystoPunkNFTId, setDystoPunkNFTId] = useState('');
  const [performDystoCheck, setPerformDystoCheck] = useState(false)
  const [dystopunkCheckHud, setDystopunkCheckHud] = useState('')
  
  const [encodeKeyID, setEncodeKeyID] = useState('');
  const [performEncodeCheck, setPerformEncodeCheck] = useState(false)
  const [encodeCheckHud, setEncodeCheckHud] = useState('')

  const handleDystoPunkNumberChange = (e) => {
    setDystoPunkNumber(String(e.target.value));
    setPerformDystoCheck(false)
  };

  const handleEncodeKeyIDChange = (e) => {
    setEncodeKeyID(String(e.target.value));
    setPerformEncodeCheck(false);
  };

  const checkDystoPunkEligibility = () => {
    const actualNumber = dystoPunkNumber - 1
    setDystoPunkNFTId(String(actualNumber))
    setPerformDystoCheck(true)
    // Add logic to check DystoPunk eligibility
    console.log(`Checking DystoPunk #${dystoPunkNumber}`);
  };

  const checkEncodeKeyEligibility = () => {
    // Add logic to check ENCODE Key eligibility
    setPerformEncodeCheck(true)
    console.log(`Checking ENCODE Key ID ${encodeKeyID}`);
  };

  const { data: dystopunkData } = useLeg1onCheckDystoToClaim({
    args: [dystoPunkNFTId],
    enabled: performDystoCheck,
  })

  const { data: encodeData } = useLeg1onCheckKeyClaim({
    args: [encodeKeyID],
    enabled: performEncodeCheck,
  })
  
  useEffect(() => {
    setDystopunkCheckHud("")
    if (dystopunkData === false) {
      setDystopunkCheckHud("Not claimed!")
    } else if (dystopunkData === true) {
      setDystopunkCheckHud("Already claimed!")
    }
  }, [dystopunkData])

  useEffect(() => {
    setEncodeCheckHud("")
    if (encodeData === false) {
      setEncodeCheckHud("Not claimed!")
    } else if (encodeData === true) {
      setEncodeCheckHud("Already claimed!")
    }
  }, [encodeData])
  

  return (<>
  <br />
    <div>
      <p className="lead">Check if a DystoPunk or an ENCODE Key can still claim the free mint by entering the DystoPunk number or the ENCODE Key ID:</p>
      <br />
      <div className="row">
        <div className="col-sm-6">
          <div className="input-group mb-3">
            <input 
              type="number" 
              min="1" 
              max="2077" 
              className="form-control" 
              placeholder="DystoPunk #" 
              aria-label="DystoPunk #" 
              aria-describedby="dystopunks-checker"
              value={dystoPunkNumber}
              onChange={handleDystoPunkNumberChange}
            />
            <button 
              className="btn btn-outline-primary" 
              type="button" 
              id="dystopunks-checker"
              onClick={checkDystoPunkEligibility}
            >
              Check
            </button>
          </div>
          <h6 className='text-white'>{dystopunkCheckHud}</h6>
        </div>

        <div className="col-sm-6">
          <div className="input-group mb-3">
            <input 
              type="number" 
              min="0" 
              max="1499" 
              className="form-control" 
              placeholder="ENCODE Key ID" 
              aria-label="ENCODE Key ID" 
              aria-describedby="encodekey-checker"
              value={encodeKeyID}
              onChange={handleEncodeKeyIDChange}
            />
            <button 
              className="btn btn-outline-primary" 
              type="button" 
              id="encodekey-checker"
              onClick={checkEncodeKeyEligibility}
            >
              Check
            </button>
          </div>
          <h6 className='text-white'>{encodeCheckHud}</h6>
        </div>
      </div>
    </div>
    </>
  );
}

export default CheckClaimEligibility